import { ListType } from 'adapters/types';
import { IntelButtonProps } from 'components/EDP/summary/IntelButton';
import { formatNumber, formatToRoundedDollars } from 'utils/util';
import { IntelButtonValues } from './getIntelButtonValues';
import { TableTypesKind } from 'modules/event/types';
import { useUserState } from 'hooks/common/useUserState';

// #region Types

// a function to set the count of the IntelButton state var by ListType
export type SetIntelButtonDataFn = (updates: IntelButtonUpdateProps[]) => void;

export type IntelButtonUpdateProps = {
  type: ListType;
  count: number;
  category: IntelButtonCategory;
  isAdditive?: boolean;
};

export type IntelButtonCategory = 'primary' | 'secondary' | 'tertiary';

export type ProspectListsMapByType = Map<ListType, number>;

export interface UseIntelButtonsResult {
  intelButtonProps: IntelButtonProps[];
}

interface UseIntelButtonsProps {
  prospectsLoading: boolean;
  dataIsLoading: boolean;
  intelButtonData: Map<ListType, IntelButtonValues>;
  tableType: TableTypesKind;
}

// #endregion

export const intelButtonIconNames = {
  [ListType.Competitors]: 'SportsKabaddiIcon' as const,
  [ListType.Customers]: 'HandshakeIcon' as const,
  [ListType.Prospects]: 'HailIcon' as const,
  [ListType.ActivePipeline]: 'BusinessCenterIcon' as const,
};

const pluralizeHelper = (isAttendee: boolean, count: number | undefined) => {
  if (isAttendee) {
    if (count === 1) {
      return 'attendee';
    } else {
      return 'attendees';
    }
  } else {
    if (count === 1) {
      return 'organization';
    } else {
      return 'organizations';
    }
  }
};

const CRM_REQUIREMENTS_MESSAGE = (
  <span>
    This list type requires CRM integration with HubSpot or Salesforce.{' '}
    <a href="mailto:support@vendelux.com">Contact us</a> for access.{' '}
  </span>
);
/**
 * Takes generated data for intel buttons and formats them into appropriate props for the <IntelButton /> components.
 * @param param0 `
 * @returns
 */
export const useIntelButtons = ({
  prospectsLoading,
  dataIsLoading,
  intelButtonData,
  tableType,
}: UseIntelButtonsProps): UseIntelButtonsResult => {
  const { team, isLoading: userStateLoading } = useUserState();
  const crmIsActive = !userStateLoading && team.crm_connected;

  const loading = prospectsLoading || dataIsLoading;
  if (tableType === TableTypesKind.Attendee) {
    return {
      intelButtonProps: [
        {
          title: 'Customers',
          intelButtonData: {
            label: pluralizeHelper(
              true,
              intelButtonData.get(ListType.Customers)?.attendees
            ),
            count: formatNumber(
              intelButtonData.get(ListType.Customers)!.attendees
            ),
            loading,
            text: intelButtonData.get(ListType.Customers)?.organizations ? (
              <span>
                from{' '}
                <b>{intelButtonData.get(ListType.Customers)?.organizations}</b>{' '}
                companies worth{' '}
                <b>
                  {formatToRoundedDollars(
                    intelButtonData.get(ListType.Customers)!.dealValue!
                  )}
                </b>{' '}
                in potential value.
              </span>
            ) : null,
          },

          buttonIcon: intelButtonIconNames[ListType.Customers],
        },
        {
          title: 'Prospects',
          intelButtonData: {
            label: pluralizeHelper(
              true,
              intelButtonData.get(ListType.Prospects)?.attendees
            ),
            count: formatNumber(
              intelButtonData.get(ListType.Prospects)!.attendees
            ),
            loading,
            text: intelButtonData.get(ListType.Prospects)?.organizations ? (
              <span>
                from{' '}
                <b>{intelButtonData.get(ListType.Prospects)?.organizations} </b>
                companies worth{' '}
                <b>
                  {formatToRoundedDollars(
                    intelButtonData.get(ListType.Prospects)!.dealValue!
                  )}
                </b>{' '}
                in potential value.
              </span>
            ) : null,
          },

          buttonIcon: intelButtonIconNames[ListType.Prospects],
        },
        {
          title: 'CRM pipeline',
          showCounts: loading || crmIsActive,
          intelButtonData: {
            label: pluralizeHelper(
              true,
              intelButtonData.get(ListType.ActivePipeline)?.attendees
            ),
            count: formatNumber(
              intelButtonData.get(ListType.ActivePipeline)!.attendees
            ),

            loading: dataIsLoading,
            text: !crmIsActive ? (
              CRM_REQUIREMENTS_MESSAGE
            ) : intelButtonData.get(ListType.ActivePipeline)?.organizations ? (
              <span>
                from{' '}
                <b>
                  {intelButtonData.get(ListType.ActivePipeline)?.organizations}
                </b>{' '}
                companies worth{' '}
                <b>
                  {formatToRoundedDollars(
                    intelButtonData.get(ListType.ActivePipeline)!.dealValue!
                  )}
                </b>{' '}
                in potential value.
              </span>
            ) : null,
          },

          buttonIcon: intelButtonIconNames[ListType.ActivePipeline],
        },
        {
          title: 'Competitors',
          intelButtonData: {
            label: pluralizeHelper(
              true,
              intelButtonData.get(ListType.Competitors)?.attendees
            ),
            count: formatNumber(
              intelButtonData.get(ListType.Competitors)?.attendees
            ),
            loading,
            text: intelButtonData.get(ListType.Competitors)?.organizations ? (
              <span>
                from{' '}
                <b>
                  {intelButtonData.get(ListType.Competitors)?.organizations}{' '}
                </b>
                companies.
              </span>
            ) : null,
          },

          buttonIcon: intelButtonIconNames[ListType.Competitors],
        },
      ],
    };
  } else if (tableType === TableTypesKind.Organization) {
    return {
      intelButtonProps: [
        {
          title: 'Customers',
          intelButtonData: {
            label: pluralizeHelper(
              false,
              intelButtonData.get(ListType.Customers)?.organizations
            ),
            count: formatNumber(
              intelButtonData.get(ListType.Customers)?.organizations
            ),
            loading,
            text: intelButtonData.get(ListType.Customers)?.dealValue ? (
              <span>
                worth{' '}
                <b>
                  {formatToRoundedDollars(
                    intelButtonData.get(ListType.Customers)?.dealValue!
                  )}{' '}
                </b>
                in potential value
              </span>
            ) : null,
          },

          buttonIcon: intelButtonIconNames[ListType.Customers],
        },
        {
          title: 'Prospects',
          intelButtonData: {
            label: pluralizeHelper(
              false,
              intelButtonData.get(ListType.Prospects)?.attendees
            ),
            count: formatNumber(
              intelButtonData.get(ListType.Prospects)?.organizations
            ),
            loading,
            text: intelButtonData.get(ListType.Prospects)?.dealValue ? (
              <span>
                worth{' '}
                <b>
                  {formatToRoundedDollars(
                    intelButtonData.get(ListType.Prospects)?.dealValue!
                  )}
                </b>{' '}
                in potential value
              </span>
            ) : null,
          },

          buttonIcon: intelButtonIconNames[ListType.Prospects],
        },
        {
          title: 'CRM pipeline',
          showCounts: loading || crmIsActive,
          intelButtonData: {
            label: pluralizeHelper(
              false,
              intelButtonData.get(ListType.ActivePipeline)?.attendees
            ),
            count: formatNumber(
              intelButtonData.get(ListType.ActivePipeline)?.organizations
            ),
            loading,
            text: !crmIsActive ? (
              CRM_REQUIREMENTS_MESSAGE
            ) : intelButtonData.get(ListType.ActivePipeline)?.dealValue ? (
              <span>
                worth{' '}
                <b>
                  {formatToRoundedDollars(
                    intelButtonData.get(ListType.ActivePipeline)?.dealValue!
                  )}
                </b>{' '}
                in potential value
              </span>
            ) : null,
          },

          buttonIcon: intelButtonIconNames[ListType.ActivePipeline],
        },
        {
          title: 'Competitors',
          intelButtonData: {
            label: pluralizeHelper(
              false,
              intelButtonData.get(ListType.Competitors)?.organizations
            ),
            count: formatNumber(
              intelButtonData.get(ListType.Competitors)?.organizations
            ),
            loading,
            text: intelButtonData.get(ListType.Competitors)?.dealValue ? (
              <span>
                worth{' '}
                <b>
                  {formatToRoundedDollars(
                    intelButtonData.get(ListType.Competitors)?.dealValue!
                  )}
                </b>{' '}
                in potential value
              </span>
            ) : null,
          },

          buttonIcon: intelButtonIconNames[ListType.Competitors],
        },
      ],
    };
  } else {
    return {} as any;
  }
};
