import React, { useCallback } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@mui/material';
import { useWatch } from 'react-hook-form-mui';
import { useListBuilder } from '../ListBuilderProvider';
import { OrganizationsInputMethod, OrganizationsInputMethods } from '../types';
import { CSVUploadProvider } from '../CSVUpload/CSVUploadContext';
import { OrganizationsCSVUploadForm } from '../CSVUpload/OrganizationsCSVUploadForm';
import { OrganizationsCRMInputForm } from '../OrganizationsCRMInputForm';
import { useUserState } from 'hooks/common/useUserState';
import { useShouldDisplayModule } from '../hooks/useModulesToDisplay';
import { usePosthog } from 'analytics';

export function OrganizationsInputMethodCard() {
  const userState = useUserState();
  const { sendPosthogEvent } = usePosthog();
  const shouldDisplay = useShouldDisplayModule();

  const {
    state: { organizationsInputMethod },
    dispatch,
    form,
    initialFormValues,
  } = useListBuilder();

  const listType = useWatch({
    control: form.control,
    name: 'listType',
  });

  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });

  const listName = useWatch({
    control: form.control,
    name: 'listName',
  });

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInputMethod = event.target.value as OrganizationsInputMethod;
      const inputMethodLabel = OrganizationsInputMethods[newInputMethod];

      // Determine event name based on input method label
      let eventName = `List Builder > ${inputMethodLabel} selected`;

      // Send analytics event
      if (eventName) {
        sendPosthogEvent(eventName, {
          listName,
          listType,
          listContents: listEntityType,
          inputMethod: newInputMethod,
        });
      }

      dispatch({
        type: 'ORGANIZATIONS_INPUT_METHOD_CHANGED',
        organizationsInputMethod: newInputMethod,
      });
    },
    [dispatch, sendPosthogEvent, listName, listType, listEntityType]
  );

  const handleFileUploaded = useCallback(
    (file) => {
      sendPosthogEvent('List Builder > CSV uploaded', {
        listName,
        listType,
        listContents: listEntityType,
        fieldName: 'includedOrganizations',
        fileName: file.name,
      });
    },
    [sendPosthogEvent, listName, listType, listEntityType]
  );

  const organizationInputMethodOptions = Object.entries(
    OrganizationsInputMethods
  ).filter((inputMethod) => {
    // Show these options for all list types.
    if (['manual', 'csv'].includes(inputMethod[0])) {
      return true;
    }

    if (inputMethod[0] === 'crm') {
      return listType === 'pipeline';
    }

    if (inputMethod[0] === 'describe') {
      return listType === 'prospects';
    }

    return true;
  });

  return (
    <Card sx={{ mb: 2 }} data-testid="organizations-input-method-card">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {listType === 'customers'
            ? 'Which organizations are your customers?'
            : listType === 'competitors'
            ? 'Which organizations are your competitors?'
            : listType === 'prospects'
            ? 'Which organizations are your prospects?'
            : listType === 'pipeline'
            ? 'Which organizations are in your pipeline?'
            : 'Which organizations do these people work at?'}
        </Typography>

        <Stack spacing={2}>
          <RadioGroup
            name="organizationsInputMethod"
            value={organizationsInputMethod}
            onChange={onChange}
          >
            {organizationInputMethodOptions.map(([key, value]) => {
              if (key === 'crm' && !userState?.team?.crm_connected) {
                return (
                  <Tooltip
                    title="Contact your CSM to enable CRM integration."
                    key={key}
                  >
                    <FormControlLabel
                      value={key}
                      control={<Radio key={`${key}-radio`} />}
                      label={value}
                      data-testid={`radio-${key}`}
                      disabled={
                        key === 'crm' && !userState?.team?.crm_connected
                      }
                    />
                  </Tooltip>
                );
              }

              return (
                <FormControlLabel
                  value={key}
                  control={<Radio />}
                  label={value}
                  data-testid={`radio-${key}`}
                />
              );
            })}
          </RadioGroup>

          {shouldDisplay('OrganizationsCRMInputForm') ? (
            <OrganizationsCRMInputForm />
          ) : null}

          {shouldDisplay('OrganizationsCSVUploadForm') ? (
            <CSVUploadProvider
              dataType="organizations"
              initialValues={initialFormValues.includedOrganizations}
              onValuesChanged={(newValues) => {
                form.setValue('includedOrganizations', newValues);
              }}
              onFileUploaded={handleFileUploaded}
            >
              <OrganizationsCSVUploadForm />
            </CSVUploadProvider>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
