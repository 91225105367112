import React, { useCallback, useEffect } from 'react';
import { Card, CardContent, Typography, Stack } from '@mui/material';
import { useListBuilder } from '../ListBuilderProvider';
import { PeopleJobTitleForm } from '../PeopleJobTitleForm';
import { CSVUploadProvider } from '../CSVUpload/CSVUploadContext';
import { usePosthog } from 'analytics';
import { useWatch } from 'react-hook-form-mui';

export function ExcludeJobTitlesCard() {
  const { form, initialFormValues } = useListBuilder();
  const { sendPosthogEvent } = usePosthog();

  const listType = useWatch({
    control: form.control,
    name: 'listType',
  });

  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });

  const listName = useWatch({
    control: form.control,
    name: 'listName',
  });

  // Reset values when the component unmounts.
  useEffect(() => {
    return () => {
      form.resetField('excludedJobTitles');
    };
  }, [form]);

  const handleFileUploaded = useCallback(
    (file) => {
      sendPosthogEvent('List Builder > CSV uploaded', {
        listName,
        listType,
        listContents: listEntityType,
        fieldName: 'excludedJobTitles',
        fileName: file.name,
      });
    },
    [sendPosthogEvent, listName, listType, listEntityType]
  );

  return (
    <Card sx={{ marginBottom: 2 }} data-testid="exclude-job-titles-card">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Do you want to exclude any job titles? (optional)
        </Typography>

        <Stack spacing={2}>
          <CSVUploadProvider
            dataType="jobTitles"
            initialValues={initialFormValues.excludedJobTitles.map((value) => ({
              value,
              label: value,
            }))}
            onValuesChanged={(newValues) => {
              form.setValue(
                'excludedJobTitles',
                newValues.map((v) => v.value)
              );
            }}
            onFileUploaded={handleFileUploaded}
          >
            <PeopleJobTitleForm fieldName="excludedJobTitles" />
          </CSVUploadProvider>
        </Stack>
      </CardContent>
    </Card>
  );
}
