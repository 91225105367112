import { Option } from 'hooks/Prospects/useIcpFilter';

export enum ProspectListPermissions {
  Read = 'Read',
  Write = 'Write',
  Owner = 'Owner',
  Rename = 'Rename',
  Delete = 'Delete',
}

export type Role = {
  email: string;
  role: Permission;
};

export type TeamMember = {
  name: string;
  email: string;
  avatar: string;
  url: string;
  team_uuid?: string;
};

export type Permission = 'Editor' | 'Viewer' | 'Owner' | 'None';

export interface TeamMembersWithPermissions extends TeamMember {
  role: Permission;
}

export enum ProspectTab {
  Lists = 'Lists',
  People = 'People',
  Orgs = 'Organizations',
}

export type ProspectListType = 'Personas' | 'People' | 'Organization' | 'CRM';
export type SmartListStatusType = 'ready' | 'pending' | null;

export interface ProspectListICP {
  name: string;
  list_source?: string;
  list_type?: ProspectListType; // this is different from ICP params list_type
  smart_list_status: SmartListStatusType;
  average_deal_size: number;
  number_of_deals: number;
  icp_params: {
    list_type: ProspectListType; // this is to be updated on the backend to list_source.
    full_titles?: string[];
    include_job_titles?: string[];
    exclude_job_titles?: string[];
    include_prospect_list?: string[];
    include_prospect_list_options?: Option[];
    exclude_prospect_list?: string[];
    exclude_prospect_list_options?: Option[];
    industry?: string[];
    organization_size?: string[];
    organization_size_buckets?: string[];
    organization_options?: Option[];
    crm_company_options?: Option[];
    crm_people_options?: Option[];
    crm_organization_sizes?: Option[];
    crm_include_job_titles_options?: Option[];
    crm_exclude_job_titles_options?: Option[];
    crm_include_prospect_list_options?: Option[];
    crm_exclude_prospect_list_options?: Option[];
    include_deal_stage_options?: Option[];
    exclude_deal_stage_options?: Option[];
    include_deal_owner_options?: Option[];
    exclude_deal_owner_options?: Option[];
    people_options?: Option[];
    contact_owner_options?: Option[];
    company_owner_options?: Option[];
    deal?: {
      'Last activity date': {
        before: string;
        after: string;
      };
      'Close date': {
        before: string;
        after: string;
      };
      'Created date': {
        before: string;
        after: string;
      };
    };
  };
  my_permissions: ProspectListPermissions[];
  shared_with: TeamMembersWithPermissions[];
}

export interface ProspectListProfile {
  domain: string;
  events: number;
  image: string;
  link: string;
  naics_codes: string[];
  name: string;
  organization: string;
  speaking: number;
  title: string;
  uuid: string;
}

export interface ProspectListOrganization {
  domain: string;
  events: number;
  image: string;
  industry: string;
  link: string;
  location: string;
  members: number;
  naics_codes: string[];
  name: string;
  unfollow: string;
  uuid: string;
}
