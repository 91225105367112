import { Stack, Typography } from '@mui/material';
import { BetaBadge } from 'components/common/Widgets/BetaBadge';
import { GridColumnHeaderParams, GridValidRowModel } from '@mui/x-data-grid';
import { ComponentProps } from 'react';

type BetaHeaderCellProps<T extends GridValidRowModel> =
  GridColumnHeaderParams<T> & {
    slotProps?: {
      stack?: ComponentProps<typeof Stack>;
      typography?: ComponentProps<typeof Typography>;
    };
  };

export const BetaHeaderCell = <T extends GridValidRowModel>({
  colDef,
  slotProps,
}: BetaHeaderCellProps<T>) => {
  return (
    <Stack alignItems="flex-start" {...slotProps?.stack}>
      {/* customize the beta badge so it looks nicer inside the column header*/}
      <BetaBadge
        sx={{
          fontSize: 10,
          height: 16,
          m: 0,
          mb: 0.5,
        }}
      />
      <Typography variant="body2" fontWeight={500} {...slotProps?.typography}>
        {colDef.headerName}
      </Typography>
    </Stack>
  );
};
