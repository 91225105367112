import { Alert, Link, Typography, useTheme } from '@mui/material';
import { MuiIconManifest } from 'utils/iconManifest';

export function LimitedResultsAlertUI() {
  const theme = useTheme();

  return (
    <Alert
      severity="warning"
      iconMapping={{
        warning: <MuiIconManifest.InfoOutlinedIcon fontSize="inherit" />,
      }}
      sx={{
        backgroundColor: theme.vdxColors.vdxYellow[250],
      }}
    >
      <Typography>Your list is limited to 50,000 results.</Typography>
      <Typography>
        Revisit filters to narrow your list of profiles or organizations.
      </Typography>
      <Typography>
        If you have questions, email{' '}
        <Link href="mailto:support@vendelux.com">support@vendelux.com</Link>.
      </Typography>
    </Alert>
  );
}

/**
 * Show cautionary text to users if the API has limited the results.
 */
export function LimitedResultsAlert({ list }: { list: any }) {
  if (list.length >= 50000) {
    return <LimitedResultsAlertUI />;
  }

  return null;
}
