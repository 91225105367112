import {
  GridFilterOperator,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import { isAncestorOf } from './helpers';

/**
 * Return a filter operator that checks if at least one of the NAICS codes in
 * in the column is a descendant of at least one of the filter codes.
 */
export function naicsCodeOverlaps<
  TRow extends GridValidRowModel,
  TValue extends any[] = any[],
  TFormatted = TValue
>(): GridFilterOperator<TRow, TValue, TFormatted> {
  return {
    label: 'Overlaps',
    value: 'naicsCodeOverlaps',
    getApplyFilterFn: (filterItem) => {
      if (!Array.isArray(filterItem.value)) return null;
      const filterValues = filterItem.value;
      return (params) => {
        if (!filterValues.length) return true;
        const values = params.value;
        return filterValues.some((filterValue) =>
          values?.some(
            (item) => isAncestorOf(filterValue, item) || filterValue === item
          )
        );
      };
    },
  };
}
