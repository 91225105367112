import { CrmFilterOptions } from 'redux/reducers/api/crm';
import {
  FilterItemFullOptions,
  FilterSection,
} from 'hooks/Prospects/useIcpFilter';
import { ProspectList } from 'adapters/types';
import { ActivatedFilters } from './Filters/AvailableFiltersSection';

export const jobTitles = [
  'Account Director',
  'Account Executive',
  'Account Manager',
  'Account manager',
  'Accountant',
  'Administrative Assistant',
  'Administrator',
  'Adjunct Professor',
  'Advisor',
  'Advisor / Consultant',
  'Advisory Board Member',
  'Affiliate Manager',
  'AI Engineer',
  'Analyst',
  'Analytics Engineer',
  'Application Engineer',
  'Architect',
  'Area Sales Manager',
  'Art Director',
  'Artist',
  'Assistant',
  'Assistant Director',
  'Assistant General Manager',
  'Assistant Manager',
  'Assistant Professor',
  'Assistant Project Manager',
  'Assistant Vice President',
  'Associate',
  'Associate Director',
  'Associate Partner',
  'Associate Professor',
  'Associate Vice President',
  'Attorney',
  'Automation Engineer',
  'Bdm',
  'Board Member',
  'Branch Manager',
  'Brand Manager',
  'Business Analyst',
  'Business Consultant',
  'Business Developer',
  'Business Development',
  'Business Development Consultant',
  'Business Development Director',
  'Business Development Executive',
  'Business Development Manager',
  'Business Development Representative',
  'Business Development Specialist',
  'Business Director',
  'Business Manager',
  'Business Owner',
  'Business Unit Manager',
  'Buyer',
  'Category Manager',
  'CCO',
  'CEO',
  'CEO & Co-founder',
  'CEO & Co-Founder',
  'CEO & Founder',
  'CEO / COO / Chairman',
  'CEO / CIO / CTO / COO / CFO / CISO / CDO',
  'CEO / Founder',
  'CEO and Co-founder',
  'CEO and Co-Founder',
  'CEO and Founder',
  'CEO, Co-founder',
  'CEO, Founder',
  'CEO/Founder',
  'CFO',
  'Chairman',
  'Channel Manager',
  'Chief Architect',
  'Chief Business Officer',
  'Chief Commercial Officer',
  'Chief Commercial Officer (CCO)',
  'Chief Customer Officer',
  'Chief Engineer',
  'Chief Executive Officer',
  'Chief Financial Officer',
  'Chief Growth Officer',
  'Chief Information Officer',
  'Chief Information Security Officer',
  'Chief Innovation Officer',
  'Chief Marketing Officer',
  'Chief Medical Officer',
  'Chief of Staff',
  'Chief Operations Officer',
  'Chief Operating Officer',
  'Chief People Officer',
  'Chief Product Officer',
  'Chief Revenue Officer',
  'Chief Revenue Officer (CRO)',
  'Chief Sales Officer',
  'Chief Sales Officer (CSO)',
  'Chief Strategy Officer',
  'Chief Strategy Officer (CSO)',
  'Chief Technology Officer',
  'CIO',
  'CISO',
  'Civil Engineer',
  'Client Partner',
  'Cloud Architect',
  'Cloud Engineer',
  'Cloud Solution Architect',
  'CMO',
  'Co CEO',
  'Co Founder',
  'Co-founder',
  'Co-Founder',
  'Co-founder & CEO',
  'Co-Founder & CEO',
  'Co-founder & COO',
  'Co-Founder & COO',
  'Co-Founder & CTO',
  'Co-founder and CEO',
  'Co-Founder and CEO',
  'Co-founder and COO',
  'Co-Founder and COO',
  'Co-founder, CEO',
  'Co-Founder, CEO',
  'Co-founder, COO',
  'COO',
  'COO & Co-Founder',
  'Cofounder',
  'Commercial',
  'Commercial Director',
  'Commercial Manager',
  'Commercial staff member, sales',
  'Communications Manager',
  'Community Manager',
  'Consultant',
  'Content Creator',
  'Controller',
  'Coordinator',
  'Country Manager',
  'CPO',
  'Creative Director',
  'CRO',
  'CSO',
  'CTO',
  'Customer Success Manager',
  'Cyber Security Analyst',
  'Cyber Security Engineer',
  'Cybersecurity Analyst',
  'Cybersecurity Engineer',
  'Data Analyst',
  'Data Architect',
  'Data Engineer',
  'Data engineer',
  'Data Scientist',
  'Data scientist',
  'Deputy Director',
  'Deputy General Manager',
  'Deputy Manager',
  'Design Engineer',
  'Designer',
  'Developer',
  'DevOps Engineer',
  'Digital Marketing Manager',
  'Digital Marketing Specialist',
  'Director',
  'DIRECTOR',
  'Director Business Development',
  'Director General',
  'Director of Business Development',
  'Director Of Business Development',
  'Director of Engineering',
  'Director of Marketing',
  'Director of Operations',
  'Director Of Operations',
  'Director of Partnerships',
  'Director of Product Management',
  'Director of Product Marketing',
  'Director of Sales',
  'Director of Sales & Marketing',
  'Director of Sales and Marketing',
  'Director of Strategic Partnerships',
  'Director, Business Development',
  'Director, Product Management',
  'Director, proprietor',
  'Editor',
  'Electrical Engineer',
  'Engineer',
  'Engineering',
  'Engineering Manager',
  'Enterprise Account Executive',
  'Enterprise Account Manager',
  'Enterprise Architect',
  'Entrepreneur',
  'Event Manager',
  'Event Marketing Manager',
  'Events Manager',
  'Executive',
  'Executive Assistant',
  'Executive Creative Director',
  'Executive Director',
  'Executive Manager',
  'Executive Producer',
  'Executive Vice President',
  'Export Manager',
  'Field Marketing Manager',
  'Finance Manager',
  'Financial Analyst',
  'Founder',
  'Founder & CEO',
  'Founder & COO',
  'Founder & Director',
  'Founder & Managing Director',
  'Founder & Managing Partner',
  'Founder & President',
  'Founder and CEO',
  'Founder and Managing Director',
  'Founder and President',
  'Founder CEO',
  'Founder / CEO',
  'Founder / Principal',
  'Founder, CEO',
  'Founder/CEO',
  'Founder/Principal',
  'Founding Partner',
  'Frontend Developer',
  'Full Stack Developer',
  'General Manager',
  'General manager',
  'General Partner',
  'Geschäftsführer',
  'GM',
  'Graphic Designer',
  'Growth Marketing Manager',
  'Head',
  'Head of Business Development',
  'Head of Engineering',
  'Head of Growth',
  'Head of Marketing',
  'Head Of Marketing',
  'Head of Operations',
  'Head of Partnerships',
  'Head of Product',
  'Head of Sales',
  'Head of Strategic Partnerships',
  'Hostess',
  'HR Business Partner',
  'HR Director',
  'HR Manager',
  'Human Resources Manager',
  'Information Security Analyst',
  'International Sales Manager',
  'Intern',
  'Investment Analyst',
  'Investment Manager',
  'Investor',
  'IT',
  'IT Consultant',
  'IT Director',
  'IT Engineer',
  'IT Manager',
  'IT Project Manager',
  'IT Specialist',
  'Journalist',
  'Key Account Manager',
  'Lawyer',
  'Lead Data Scientist',
  'Lead Engineer',
  'Lead Software Engineer',
  'Lecturer',
  'Machine Learning Engineer',
  'Management / Owner',
  'Manager',
  'MANAGER',
  'Manager commercial/marketing',
  'Manager technical / engineering',
  'Manager, head of department',
  'Managing Director',
  'Managing Partner',
  'Manufacturing Engineer',
  'Marketing',
  'Marketing Analyst',
  'Marketing Consultant',
  'Marketing Coordinator',
  'Marketing Director',
  'Marketing Executive',
  'Marketing Manager',
  'Marketing Project Manager',
  'Marketing Specialist',
  'MD',
  'Mechanical Engineer',
  'Member',
  'Member Board of Directors',
  'N / A',
  'National Account Manager',
  'National Sales Manager',
  'Network Engineer',
  'Office Manager',
  'Operations',
  'Operations Director',
  'Operations Manager',
  'Owner',
  'OWNER',
  'Owner / Partner',
  'OWNER/CEO/PRESIDENT/PARTNER',
  'Partner',
  'Partner Account Manager',
  'Partner Development Manager',
  'Partner Manager',
  'Partnerships Manager',
  'Pharmacist',
  'Photographer',
  'Portfolio Manager',
  'President',
  'PRESIDENT',
  'President & CEO',
  'President & COO',
  'President / CEO',
  'President / Partner',
  'President and CEO',
  'President and Chief Executive Officer',
  'President, CEO',
  'President/CEO',
  'President/Partner',
  'Principal',
  'Principal Architect',
  'Principal Consultant',
  'Principal Engineer',
  'Principal Product Manager',
  'Principal Solutions Architect',
  'Principal Software Engineer',
  'Process Engineer',
  'Producer',
  'Product Designer',
  'Product Director',
  'Product Engineer',
  'Product Manager',
  'Product manager',
  'Product Marketing',
  'Product Marketing Manager',
  'Product Owner',
  'Product Specialist',
  'Production Manager',
  'Professor',
  'Program Director',
  'Program Manager',
  'Project Coordinator',
  'Project Director',
  'Project Engineer',
  'Project Lead',
  'Project Manager',
  'Project manager',
  'Procurement Manager',
  'Purchasing Manager',
  'QA Engineer',
  'Quality Engineer',
  'R & D Engineer',
  'Realtor',
  'Recruiter',
  'Regional Director',
  'Regional Manager',
  'Regional Sales Director',
  'Regional Sales Manager',
  'Regional Vice President',
  'Relationship Manager',
  'Reporter',
  'Representative',
  'Research Analyst',
  'Research Assistant',
  'Research Engineer',
  'Research Scientist',
  'Researcher',
  'SALES',
  'Sales',
  'Sales & Marketing Manager',
  'Sales Account Manager',
  'Sales Consultant',
  'Sales Development Representative',
  'Sales Director',
  'Sales Engineer',
  'Sales Executive',
  'Sales manager',
  'Sales Manager',
  'Sales person',
  'Sales Representative',
  'Sales Specialist',
  'Security Analyst',
  'Security Architect',
  'Security Consultant',
  'Security Engineer',
  'Senior Account Executive',
  'Senior Account Manager',
  'Senior Advisor',
  'Senior Analyst',
  'Senior Associate',
  'Senior Business Development Manager',
  'Senior Consultant',
  'Senior Customer Success Manager',
  'Senior Data Engineer',
  'Senior Data Scientist',
  'Senior Developer',
  'Senior Director',
  'Senior Engineer',
  'Senior Engineering Manager',
  'Senior Marketing Manager',
  'Senior Manager',
  'Senior Partner',
  'Senior Product Manager',
  'Senior Product Marketing Manager',
  'Senior Program Manager',
  'Senior Project Engineer',
  'Senior Project Manager',
  'Senior Sales Engineer',
  'Senior Sales Executive',
  'Senior Sales Manager',
  'Senior Software Developer',
  'Senior Software Engineer',
  'Senior Solution Architect',
  'Senior Solutions Architect',
  'Senior Solutions Consultant',
  'Senior Solutions Engineer',
  'Senior Systems Engineer',
  'Senior Vice President',
  'Social Media Manager',
  'Software Architect',
  'Software Developer',
  'Software developer',
  'Software Engineer',
  'Software engineer',
  'Software Engineering Manager',
  'Solution Architect',
  'Solution Engineer',
  'Solutions Architect',
  'Solutions Consultant',
  'Solutions Engineer',
  'Specialist',
  'Staff',
  'Staff Engineer',
  'Staff Software Engineer',
  'Strategic Account Director',
  'Strategic Account Executive',
  'Strategic Account Manager',
  'Strategic Advisor',
  'Strategic Partnerships',
  'Student',
  'Supervisor',
  'Support Engineer',
  'SVP',
  'System Administrator',
  'System Engineer',
  'Systems Administrator',
  'Systems Engineer',
  'Sr. Account Executive',
  'Sr. Director',
  'Sr. Manager',
  'Sr. Sales Engineer',
  'SVP',
  'Talent Acquisition Specialist',
  'Teacher',
  'Team Lead',
  'Team Leader',
  'Technical Architect',
  'Technical Director',
  'Technical Lead',
  'Technical Manager',
  'Technical Project Manager',
  'Technical Sales Engineer',
  'Technical Support Engineer',
  'Technician',
  'Territory Manager',
  'UX Designer',
  'Venture Partner',
  'Vice President',
  'Vice President Business Development',
  'Vice President Marketing',
  'Vice President of Business Development',
  'Vice President Of Business Development',
  'Vice President of Marketing',
  'Vice President of Operations',
  'Vice President of Sales',
  'Vice President Operations',
  'Vice President Sales',
  'Vice President, Business Development',
  'Vice President, Sales',
  'Videographer',
  'VP',
  'VP Business Development',
  'VP Engineering',
  'VP Marketing',
  'VP of Business Development',
  'VP of Engineering',
  'VP of Marketing',
  'VP of Sales',
  'VP Operations',
  'VP Sales',
  'Web Developer',
];

// NOTE: some overlap with type EmployeesRange in src/modules/event/types.ts
export const organizationSizeRanges = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1K-5K',
  '5K-10K',
  '10K-50K',
  '50K-100K',
  '100K+',
];

export const organizations = [
  'Airtable',
  'Google',
  'Facebook',
  'Microsoft',
  'Amazon',
  'Apple',
  'Netflix',
  'Twitter',
  'Uber',
  'Lyft',
  'Salesforce',
  'LinkedIn',
  'Dropbox',
  'Slack',
  'Zoom',
  'TikTok',
  'Snapchat',
  'Twitch',
  'Tinder',
  'Spotify',
  'Shopify',
  'Square',
  'Stripe',
  'Robinhood',
  'Coinbase',
  'Reddit',
  'PayPal',
  'Pinterest',
  'DoorDash',
  'Instacart',
  'Postmates',
  'Airbnb',
];

export const orgSizes = ['SMB', 'Midmarket', 'Enterprise'];

export const datesOptions: FilterItemFullOptions = [
  {
    title: 'Today',
    description: 'From midnight today until the current time',
  },
  {
    title: 'Yesterday',
    description: 'The previous 24-hour day',
  },
  {
    title: 'Tomorrow',
    description: 'The next 24-hour day',
  },
  {
    title: 'This week',
    description: 'The current calendar week',
  },
  {
    title: 'This week so far',
    description: 'The current calendar week up to now',
  },
  {
    title: 'Last week',
    description: 'The previous calendar week',
  },
];

export const activatedFiltersInitialState: ActivatedFilters = {
  Personas: {
    'include|Job titles': [],
  },
  People: {
    People: [],
  },
  Organization: {
    Organization: [],
  },
  CRM: {
    'Company Owner': [],
  },
};

export const formatAvailableFilters: (
  prospectLists: ProspectList[],
  crmFilterOptions: CrmFilterOptions['results']['grouped_crm_filters'],
  subscriptionInfo: string,
  hasCrmData: boolean
) => FilterSection[] = (
  prospectLists,
  crmFilterOptions,
  subscriptionInfo,
  hasCrmData
) => [
  {
    title: 'Personas',
    subtitle: 'Apply at least one filter to define your persona.',
    description: 'Ex. CMOs in tech',
    filters: [
      {
        title: 'Job titles',
        description: 'include',
        options: jobTitles.map((title) => ({
          label: title,
          value: title,
          disabled: false,
        })),
        allowCsvUpload: true,
        disabled: () => false,
      },
      {
        title: 'Job titles',
        description: 'exclude',
        options: jobTitles.map((title) => ({
          label: title,
          value: title,
          disabled: false,
        })),
        allowCsvUpload: true,
        disabled: () => false,
      },
      {
        title: 'Prospect list',
        description: 'include',
        options: prospectLists.map((list) => ({
          label: list.name,
          value: list.uuid,
          disabled: false,
        })),
        allowCsvUpload: false,
        isLink: true,
        disabled: (condition) => condition || false,
      },
      {
        title: 'Prospect list',
        description: 'exclude',
        options: prospectLists.map((list) => ({
          label: list.name,
          value: list.uuid,
          disabled: false,
        })),
        allowCsvUpload: false,
        isLink: true,
        disabled: (condition) => condition || false,
      },
      {
        title: 'Organization size',
        options: orgSizes.map((size) => ({
          label: size,
          value: size,
          disabled: false,
        })),
        allowCsvUpload: false,
        disabled: () => false,
      },
    ],
  },
  {
    title: 'People',
    subtitle: "Add at least one name to create 'people' list",
    description: 'Find or upload leads or contacts',
    filters: [
      {
        title: 'People',
        allowCsvUpload: true,
        fetch: true,
        disableCustomValues: true,
      },
    ],
  },
  {
    title: 'Organization',
    subtitle: "Add at least one name to create 'organization' list",
    description: 'Find or upload target companies',
    filters: [
      {
        title: 'Organization',
        fetch: true,
        allowCsvUpload: true,
        disableCustomValues: true,
      },
    ],
  },
  {
    title: 'CRM',
    isBeta: true,
    subtitle:
      'Create a smart list to automatically pull all contacts or all companies from your CRM',
    description: hasCrmData
      ? 'Your integrated data'
      : 'Integrate your CRM data',
    filters: [
      {
        title: 'Company Owner',
        allowCsvUpload: false,
        options: [
          {
            label: 'Anyone (All company owners)',
            value: 'Anyone',
            disabled: false,
          },
        ].concat(
          crmFilterOptions.OWNER.map((option) => ({
            label: option.label,
            value: option.uuid,
            disabled: subscriptionInfo !== 'Enterprise',
          }))
        ),
        disabled: () =>
          subscriptionInfo !== 'Enterprise' && subscriptionInfo !== 'Plus',
        disableCustomValues: true,
      },
      {
        title: 'Contact Owner',
        allowCsvUpload: false,
        options: [
          {
            label: 'Anyone (All contact owners)',
            value: 'Anyone',
            disabled: false,
          },
        ].concat(
          crmFilterOptions.OWNER.map((option) => ({
            label: option.label,
            value: option.uuid,
            disabled: subscriptionInfo !== 'Enterprise',
          }))
        ),
        disabled: () =>
          subscriptionInfo !== 'Enterprise' && subscriptionInfo !== 'Plus',
        disableCustomValues: true,
      },
      {
        title: 'Deal owner',
        description: 'include',
        options: [
          {
            label: 'Anyone (All deal owners)',
            value: 'Anyone',
            disabled: false,
          },
        ].concat(
          crmFilterOptions.OWNER.map((option) => ({
            label: option.label,
            value: option.uuid,
            disabled: false,
          }))
        ),
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
      },
      {
        title: 'Deal owner',
        description: 'exclude',
        options: [
          {
            label: 'Anyone (All deal owners)',
            value: 'Anyone',
            disabled: false,
          },
        ].concat(
          crmFilterOptions.OWNER.map((option) => ({
            label: option.label,
            value: option.uuid,
            disabled: false,
          }))
        ),
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
      },

      {
        title: 'Deal created date',
        fullOptions: datesOptions,
        options: datesOptions!.map((option) => ({
          label: option.title,
          value: option.title,
          disabled: false,
        })),
        unique: true,
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
        type: 'date',
      },
      {
        title: 'Deal last activity date',
        fullOptions: datesOptions,
        options: datesOptions!.map((option) => ({
          label: option.title,
          value: option.title,
          disabled: false,
        })),
        unique: true,
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
        type: 'date',
      },
      {
        title: 'Deal stage',
        description: 'include',
        options: crmFilterOptions.DEAL_STAGE.map((option) => ({
          label: option.label,
          value: option.uuid,
          disabled: false,
        })),
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
      },
      {
        title: 'Deal stage',
        description: 'exclude',
        options: crmFilterOptions.DEAL_STAGE.map((option) => ({
          label: option.label,
          value: option.uuid,
          disabled: false,
        })),
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
      },
      {
        title: 'Deal close date',
        fullOptions: datesOptions,
        options: datesOptions!.map((option) => ({
          label: option.title,
          value: option.title,
          disabled: false,
        })),
        unique: true,
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
        type: 'date',
      },
    ],
  },
];
