import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { SectionLayout } from 'components/common/Widgets/SectionLayout';
import React from 'react';
import { ListBuilderProvider } from 'components/ListBuilder4/ListBuilderProvider';

import { SaveButton } from 'components/ListBuilder4/SaveButton';
import { ListBuilder } from 'components/ListBuilder4/ListBuilder';

export function _NewListRoute() {
  return (
    <ListBuilderProvider>
      <SectionLayout
        title={'New list'}
        rightsideToolbar={<SaveButton />}
        slotProps={{
          container: {
            maxWidth: 'xxl',
          },
        }}
      >
        <ListBuilder />
      </SectionLayout>
    </ListBuilderProvider>
  );
}

export default withAppAuthenticationRequired(_NewListRoute);
