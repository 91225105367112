import { Box } from '@mui/material';
import React from 'react';
const repeat = (count: number) => Array.apply(null, Array(count));

export interface LoaderProps {
  gap?: number;
  count?: number;
  barWidth?: number;
  barHeight?: number;
  duration?: number;
  fill?: string;
}
export const Loader = ({
  gap = 6,
  count = 4,
  barWidth = 4,
  barHeight = 16,
  duration = 0.8,
  fill = '#543fed',
  ...rest
}: LoaderProps) => {
  const viewWidth = (barWidth + gap) * count - gap;

  return (
    <svg width={viewWidth} height={barHeight * 3} {...rest}>
      {repeat(count).map((_: any, i: number) => {
        return (
          <rect
            key={`rect-${i}`}
            fill={fill}
            height={barHeight}
            width={barWidth}
            x={(barWidth + gap) * i}
            y={barHeight}
          >
            <animateTransform
              attributeName="transform"
              type="translate"
              values="0 0; 0 8; 0 -8; 0 0"
              keyTimes="0; 0.25; 0.75; 1"
              dur={`${duration}s`}
              begin={`${(-duration / (count + 1)) * (count - i)}s`}
              repeatCount="indefinite"
            />
          </rect>
        );
      })}
    </svg>
  );
};

export function LoaderBlock(props: LoaderProps = {}) {
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }} data-testid="loader-block">
      <Loader {...props} />
    </Box>
  );
}

export default Loader;
