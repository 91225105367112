import { Link, Stack, Tooltip } from '@mui/material';
import { ComponentProps } from 'react';
import { MuiIconManifest } from 'utils/iconManifest';

interface UpgradeLinkCellProps {
  hasIcon?: boolean;
  slotProps?: {
    tooltip?: Omit<ComponentProps<typeof Tooltip>, 'children'>;
  };
}

export const UpgradeLinkCell = ({
  slotProps,
  hasIcon = true,
}: UpgradeLinkCellProps) => (
  <Tooltip arrow title={slotProps?.tooltip?.title ?? undefined}>
    <Stack spacing={1} direction="row" alignItems="center">
      {hasIcon && (
        <MuiIconManifest.WarningIcon color="inherit" fontSize="small" />
      )}
      <Link href="https://vendelux.com/pricing">Upgrade</Link>
    </Stack>
  </Tooltip>
);
