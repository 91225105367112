import { z } from 'zod';
import { ListEntityTypes, ListTypes } from './types';
import { Option, optionSchema } from 'hooks/Prospects/useIcpFilter';

export const listBuilderFormSchema = z.object({
  listName: z
    .string()
    .min(3, {
      message: 'Must be at least 3 characters long.',
    })
    .max(50),
  listType: z.enum(Object.keys(ListTypes) as [keyof typeof ListTypes], {
    message: 'Required.',
  }),

  listEntityType: z.enum(
    Object.keys(ListEntityTypes) as [keyof typeof ListEntityTypes]
  ),
  includedPeople: z.array(optionSchema),
  includedOrganizations: z.array(optionSchema),
  includedJobTitles: z.array(z.string()),
  excludedJobTitles: z.array(z.string()),
  includedNaics2022Codes: z.array(z.string()),
  includedProspectLists: z.array(z.string()),
  includedEmployeeRanges: z.array(z.string()),
  includedCompanyOwners: z.array(z.string()),
  includedContactOwners: z.array(z.string()),
  includedDealStages: z.array(z.string()),
  excludedDealStages: z.array(z.string()),
  includedDealOwners: z.array(z.string()),
  excludedDealOwners: z.array(z.string()),
  averageDealSize: z.number(),
});

export const defaultListBuilderFormValues = {
  listName: '',
  listType: '' as keyof typeof ListTypes,
  listEntityType: 'profiles' as keyof typeof ListEntityTypes,
  includedPeople: [] as Option[],
  includedOrganizations: [] as Option[],
  includedJobTitles: [] as string[],
  excludedJobTitles: [] as string[],
  includedNaics2022Codes: [] as string[],
  includedProspectLists: [] as string[],
  includedEmployeeRanges: [] as string[],
  includedCompanyOwners: [] as string[],
  includedContactOwners: [] as string[],
  includedDealStages: [] as string[],
  excludedDealStages: [] as string[],
  includedDealOwners: [] as string[],
  excludedDealOwners: [] as string[],
  averageDealSize: -1,
};

export type ListBuilderFormSchema = z.infer<typeof listBuilderFormSchema>;
