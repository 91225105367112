import { useAuth0 } from '@auth0/auth0-react';
import { ProtectedAdminLayout } from 'routes/layouts/ProtectedAdminLayout';
import React, {
  ComponentProps,
  ComponentType,
  useEffect,
  useMemo,
} from 'react';
import { RedirectingLabel } from './RedirectingLabel';
import { useUserState } from 'hooks/common/useUserState';
import { useLocation } from 'react-router-dom';

type WithAppAuthenticationRequiredOptions = {
  slotProps?: {
    layout?: ComponentProps<typeof ProtectedAdminLayout>;
  };
  applyLayout?: boolean;
};

/**
 * HOC that checks if the user is authenticated and redirects to the login page
 * if not.
 */
export function withAppAuthenticationRequired<TProps extends {}>(
  Component: ComponentType<TProps>,
  {
    slotProps: { layout = {} } = {},
    applyLayout = true,
  }: WithAppAuthenticationRequiredOptions = {}
) {
  return function WithAppAuthenticationRequired(props: TProps) {
    const { isAuthenticated, isFetching } = useUserState();

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const loginHint = searchParams.get('vdx_login_hint');
    const { loginWithRedirect } = useAuth0();

    const couldBecomeAuthenticated = useMemo(
      () => !isAuthenticated && isFetching,
      [isAuthenticated, isFetching]
    );
    useEffect(() => {
      if (isAuthenticated || couldBecomeAuthenticated) {
        return;
      }
      // definitely not authenticated
      (async () => {
        await loginWithRedirect({
          authorizationParams: loginHint
            ? {
                login_hint: loginHint ?? undefined,
                screen_hint: 'signup',
              }
            : undefined,
          appState: { returnTo: window.location.pathname },
        });
      })().catch(console.error);
    }, [
      isAuthenticated,
      couldBecomeAuthenticated,
      loginWithRedirect,
      loginHint,
    ]);

    if (couldBecomeAuthenticated) return null; // maybe add loading indicator
    return isAuthenticated ? (
      applyLayout ? (
        <ProtectedAdminLayout {...layout}>
          <Component {...props} />
        </ProtectedAdminLayout>
      ) : (
        <Component {...props} />
      )
    ) : (
      <RedirectingLabel />
    );
  };
}
