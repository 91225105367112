/**
 * Implementation of lodash uniqBy function. Returns subset of array with unique values based on iteratee function.
 *
 * @param arr
 * @param iteratee
 * @returns
 */
export function uniqBy<T>(
  arr: T[],
  iteratee: (item: T) => string | number
): T[] {
  const seen: Record<string, boolean> = {};
  return arr.filter((item) => {
    const key = iteratee(item);

    if (seen[key]) {
      return false;
    } else {
      seen[key] = true;
      return true;
    }
  });
}
