import { Card, CardContent, Container } from '@mui/material';
import { useListBuilder } from '../ListBuilderProvider';
import { useWatch } from 'react-hook-form';
import { LimitedResultsAlert } from 'components/ProspectList/LimitedResultsAlert';
import { DataGridProvider } from 'components/common/DataGrid/DataGridProvider';
import {
  ProspectListOrganization,
  ProspectListProfile,
} from 'modules/prospects/types';
import { useGridMetaData } from 'components/common/Context/PageDataContext';
import {
  organizationColumns,
  profileColumns,
} from 'components/ProspectList/listViewGridConstants';
import { useMemo } from 'react';

export function PreviewCard() {
  const {
    state: { organizations, people },
    form,
    previewIsLoading,
  } = useListBuilder();

  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });

  const rows =
    listEntityType === 'profiles'
      ? people
      : listEntityType === 'organizations'
      ? organizations
      : [];

  const gridRef = useGridMetaData('list');

  const columns = useMemo(() => {
    if (listEntityType === 'profiles') {
      return profileColumns.filter(
        ({ field }: { field: string }) =>
          !['speaking', 'events'].includes(field)
      );
    } else {
      return organizationColumns.filter(({ field }: { field: string }) =>
        ['name', 'members', 'domain', 'naics_codes'].includes(field)
      );
    }
  }, [listEntityType]);

  return (
    <Card data-testid="preview-card">
      <CardContent>
        <Container maxWidth="lg">
          <LimitedResultsAlert list={rows} />
          <DataGridProvider<ProspectListProfile | ProspectListOrganization>
            apiRef={gridRef.ref}
            rows={rows ?? []}
            columns={columns}
            rowsLoading={previewIsLoading}
            singularRowName={
              listEntityType === 'profiles' ? 'profile' : 'organization'
            }
            slotProps={{
              datagrid: {
                getRowId: (row) => row.uuid,
                noRowsOverlayText: `There are no ${listEntityType} in this list.`,
              },
            }}
          />
        </Container>
      </CardContent>
    </Card>
  );
}
//     </CardContent>
