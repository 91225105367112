import React from 'react';
import { Link, useTheme } from '@mui/material';
import { CustomGridColDef } from '../DataGridProvider';
import { GridValidRowModel } from '@mui/x-data-grid-premium';

/**
 * Component to display the appropriate tooltip content for status columns
 * based on the column field and header name.
 */
export const StatusTooltipContent = <T extends GridValidRowModel>({
  column,
}: {
  column: CustomGridColDef<T>;
}) => {
  const theme = useTheme();
  const field = column.field;
  const headerName = column.headerName;

  // Tooltip content based on column type
  const getContent = () => {
    // For attendees tab's status column
    if (field === 'status' && headerName === 'Status') {
      return (
        <>
          This relates to their status of attending the event: "Confirmed"
          attendees have registered or announced their attendance. "Predicted"
          attendees are forecast by AI based on numerous factors, including past
          participation. "Historical" attendees have attended at some point in
          the past.{' '}
        </>
      );
    }
    // For organization tab's presence at event column
    else if (
      field === 'status' &&
      (headerName === 'Presence at event' || headerName?.includes('Presence'))
    ) {
      return (
        <>
          This relates to any presence this organization has at this event which
          could be sponsoring, sending delegates or speaking. "Confirmed" have
          registered or announced their presence. "Predicted" are forecasted by
          AI based on many factors, including past participation. "Historical"
          have sponsored at some point in the past.{' '}
        </>
      );
    }
    // For organization tab's speaking status column
    else if (field === 'speakingStatus') {
      return (
        <>
          This relates to an organization having a speaker at this event:
          "Confirmed" have registered or announced their presence. "Predicted"
          are forecasted by AI based on many factors, such as past
          participation. "Historical" have sponsored at some point in the past.{' '}
        </>
      );
    }
    // For organization tab's sponsoring status column
    else if (field === 'sponsoringStatus') {
      return (
        <>
          This relates to their status of sponsoring an event: "Confirmed"
          sponsors have registered or announced their sponsorship. "Predicted"
          sponsors are forecasted by AI based on numerous factors, including
          past participation. "Historical" sponsors have sponsored at some point
          in the past.{' '}
        </>
      );
    }
    // Default fallback
    return (
      <>
        This relates to their status: "Confirmed" means registered or announced.
        "Predicted" means forecast by AI based on numerous factors. "Historical"
        means participated at some point in the past.{' '}
      </>
    );
  };

  return (
    <>
      {getContent()}
      <Link
        target="_blank"
        rel="noreferrer"
        underline="always"
        color={theme.palette.common.white}
        href="https://vendelux.com/help/what-are-vendelux-attendee-statuses-and-what-do-they-mean/"
      >
        Click here to learn more
      </Link>
      .
    </>
  );
};
