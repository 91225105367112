import { ANALYTICS_ENABLED } from './variables/constants';
import { store, useAppDispatch } from './redux/store';

import { useUserState } from './hooks/common/useUserState';
import { useCallback, useEffect } from 'react';
import { setUserIdentifiers } from './redux/reducers/auth';
import { userApi } from './redux/reducers/api/user';
import { useHistory } from 'react-router';
import { usePostHog as usePostHogProvider } from 'posthog-js/react';

declare global {
  interface Window {
    gtag(method: 'set', props: Record<string, any>): void;
    gtag(
      method: string | undefined,
      action: string | undefined,
      props?: Record<string, any>
    ): void;
  }
}

export function useGoogleAnalytics() {
  const {
    email,
    profile: { uuid: userId },
  } = useUserState();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!ANALYTICS_ENABLED) return;
    if (userId) {
      window.gtag('event', 'login', { user_id: userId, email });
      window.gtag('set', { user_id: userId, email });
    }
    dispatch(setUserIdentifiers(userId ? { userId, email } : null));
  });

  const { listen: historyListen } = history;
  const { refetch } = userApi.useGetUserStateQuery();
  useEffect(() => {
    trackPageView();
    historyListen(trackPageView);
    historyListen(refetch);
  }, [historyListen, refetch]);
}

/**
 * Send a user interaction event to Google Analytics.
 *
 * @param category The GA event category.
 * @param action The GA event action
 * @param label The GA event label.
 * @param value The GA event value, or any additional custom event properties.
 * @param params Additional event parameters
 */
export function sendAnalyticsEvent(
  category: string,
  action: string,
  label?: string,
  value?: number | object,
  params?: Record<string, any>
) {
  if (!ANALYTICS_ENABLED) return;

  try {
    const { userId, email } = store.getState().auth.userIdentifiers ?? {
      userId: undefined,
      email: undefined,
    };
    window.gtag('event', action, {
      event_category: category,
      ...(label ? { event_label: label } : {}),
      ...(value ? (typeof value === 'object' ? value : { value }) : {}),
      ...(userId ? { 'User ID': userId } : {}),
      ...(email ? { 'User Email': email } : {}),
      ...(params ? params : {}),
    });
  } catch (e) {
    console.error(e);
  }
}

export type SendPosthogEventFnType = (
  event: string,
  metadata?: Record<string, any>
) => void;

// Track whether the effects have run, so we only run them once max per JS load.
//  this is a React anti-pattern, but the alternative (using refs to track this flag)
//  won't work in react 17 since the ref value won't update properly across different
//  invocations of the hook.
let hasIdentifiedUser = false;
let hasIdentifiedGroup = false;

export function usePosthog() {
  const { email, profile, team, subscription, impersonating } = useUserState();

  const posthog = usePostHogProvider();

  /*
   * We use Posthog React Provider to initialize posthog, meaning that this should
   * occur after Posthog library is ready in the DOM.
   */
  useEffect(() => {
    // If Analytics flag is disabled, exit early
    if (!ANALYTICS_ENABLED) return;

    // TODO (sid): Identify sends event, consider limiting frequency of use here
    // Setup Posthog user identity
    //  Note the deep null checking; this is because the default values for useUserState are empty strings.
    if (
      !hasIdentifiedUser &&
      !!posthog &&
      !!profile &&
      !!profile.uuid &&
      !!profile.name &&
      !!email
    ) {
      posthog.identify(profile.uuid, {
        id: profile.uuid,
        name: profile.name,
        email,
        // TODO (sid): Consider impersonation - for now use user attribute to filter downstream
        impersonating, // for now, we'll track impersonation with a flag
      });

      hasIdentifiedUser = true;
    }
  }, [posthog, profile, profile.uuid, profile.name, email, impersonating]);

  useEffect(() => {
    if (!ANALYTICS_ENABLED) return;

    // Setup Posthog group identity
    if (
      !hasIdentifiedGroup &&
      !!posthog &&
      !!team &&
      !!team.uuid &&
      !!team.name &&
      !!subscription
    ) {
      posthog.group('team', team.uuid, {
        id: team.uuid,
        name: team.name,
        subscription,
      });

      hasIdentifiedGroup = true;
    }
  }, [posthog, team, team.uuid, team.name, subscription]);

  const sendPosthogEvent: SendPosthogEventFnType = useCallback(
    (event, metadata) => {
      // If Analytics flag is disabled, exit early
      if (!ANALYTICS_ENABLED) return;

      // NOTE: events should be associated with the identity & group
      //       set at the posthog config level (in usePosthog() above)
      posthog.capture(event, metadata);
    },
    [posthog]
  );

  return { posthog, sendPosthogEvent };
}

export function trackPageView() {
  if (!ANALYTICS_ENABLED) return;

  try {
    const params = new URLSearchParams(window.location.search);
    [...params].filter(([key, _val]) => key.startsWith('utm_'));
    const { userId, email } = store.getState().auth.userIdentifiers ?? {
      userId: undefined,
      email: undefined,
    };
    window.gtag('event', 'page_view', {
      page_location: `${window.location.origin}${window.location.pathname}`,
      page_path: window.location.pathname,
      page_title: document.title,
      ...(userId ? { 'User ID': userId } : {}),
      ...(email ? { 'User Email': email } : {}),
    });
  } catch (e) {
    console.error(e);
  }
}
