import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormErrorContainer } from '../FormError';
import { useEffect } from 'react';
import { CSVColumnsRow } from './CSVColumnsRow';
import { useCSVUploadContext } from './CSVUploadContext';

export const formSchema = z.object({
  title: z.string(),
});

const defaultValues = {
  title: '',
};

export type JobTitleCSVColumnsFormSchema = z.infer<typeof formSchema>;

export interface JobTitleCSVColumnsFormProps {
  columns: string[];
  onColumnSelected?: (fieldName: string, column: string) => void;
}

export function JobTitleCSVColumnsForm() {
  const { columns, setSelectedColumn, setSelectedColumnType } =
    useCSVUploadContext();
  return (
    <JobTitleCSVColumnsFormUI
      columns={columns}
      onColumnSelected={(name, value) => {
        setSelectedColumnType(name);
        setSelectedColumn(value);
      }}
    />
  );
}

export function JobTitleCSVColumnsFormUI({
  columns,
  onColumnSelected,
}: JobTitleCSVColumnsFormProps) {
  const form = useForm<JobTitleCSVColumnsFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  // When a column is selected, let the parent component know what field was matched with which column.
  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (onColumnSelected && name) {
        onColumnSelected(name, value[name] as string);
      }
    }, defaultValues);
    return () => subscription.unsubscribe();
  }, [form.watch, form, onColumnSelected]);

  return (
    <Box data-testid="job-titles-csv-columns-form">
      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
        Select column
      </Typography>

      <CSVColumnsRow<JobTitleCSVColumnsFormSchema>
        columns={columns}
        form={form}
        fieldName="title"
        label="Title"
      />
      <FormErrorContainer message={form.formState.errors.title} />
    </Box>
  );
}
