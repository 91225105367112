import { Avatar, Box, Stack, Tooltip } from '@mui/material';
import {
  GridCellParams,
  GridFilterItem,
  GridValueFormatterParams,
} from '@mui/x-data-grid-premium';
import {
  DateFilterKind,
  DealInfo,
  ProspectListMap,
  TableRow,
} from 'modules/event/types';
import { ListOwnershipDetails } from 'hooks/EDP/useProspects';
import { ChipsList } from 'components/EDP/table/ChipsList';
import { DealCell } from 'components/common/DataGrid/Cells/DealCell';
import { BetaHeaderCell } from 'components/common/DataGrid/Cells/BetaHeaderCell';
import { VerticalTypographyCell } from 'components/common/DataGrid/Cells/VerticalTypographyCell';
import { convertNumberLikesToNumber } from 'utils/util';
import { CustomGridColDef } from 'components/common/DataGrid/DataGridProvider';
import { EDP_AVATAR_PLACEHOLDER_URL } from 'variables/constants';
import {
  checkRowDate,
  getApplyFilterFn,
} from 'components/common/DataGrid/useFormatCustomColumns';
import { MuiIconManifest } from 'utils/iconManifest';
import { ListType } from 'adapters/types';
import { intelButtonIconNames } from 'hooks/EDP/useIntelButtons';
import { allCodeInfos, ancestors, ancestorsAt } from '../../../../utils/naics';
import { naicsCodeOverlaps } from '../../../../utils/naics/operators';
import { digitsOf } from '../../../../utils/naics/helpers';
import { captureMessage } from '@sentry/react';

export interface GetEDPColsProps {
  prospectListsMap: ProspectListMap;
}

// #region helper functions

/**
 * Custom function to check if a row belongs to a specific list or a specific owner
 * @returns {boolean} Returns true if the row belongs to the specified list or owner, otherwise false.
 */
function checkOwnerOrProspectList(
  { value }: GridCellParams<TableRow, ListOwnershipDetails[]>, // parameters representing a cell in the data grid
  filterItem: GridFilterItem // filter item containing values to compare with the cell's value
): boolean {
  // remove any empty strings from the filter item value array
  const filterValues = filterItem.value?.filter((v: string) => v !== '') ?? [];
  if (filterValues.length === 0 && value?.length === 0) return true;
  return (value ?? []).some((rowListUuid) =>
    filterValues.includes(rowListUuid)
  );
}

function checkDealInfo(
  { value }: GridCellParams<TableRow, DealInfo[]>, // parameters representing a cell in the data grid
  filterItem: GridFilterItem // filter item containing values to compare with the cell's value
): boolean {
  const arrayOfVals = (String(value ?? '') ?? '').split(', '); // split the value of the cell into an array of strings
  if (!Array.isArray(filterItem.value)) return false; // if the filter item value is not an array, return false (should not be possible)
  if (!Array.isArray(arrayOfVals)) return filterItem.value.length === 0; // if the value of the cell is not an array, return true if the filter item value is an empty array

  // check if any of the values in the filter item value array are included in the array of values
  return arrayOfVals.some((deal: string) => {
    return filterItem.value?.includes(deal);
  });
}

function checkNumDeals(
  { value }: GridCellParams<TableRow, (number | null)[]>, // parameters representing a cell in the data grid
  filterItem: GridFilterItem // filter item containing values to compare with the cell's value
): boolean {
  if (!value && filterItem.value.includes(0)) return true;
  return value ? filterItem.value.includes(value) : false;
}

function convertToNumberAndSort(
  v1: string | number | Record<string, number>,
  v2: string | number | Record<string, number>
) {
  if (!v1 || !v2) return 0;
  const valueA = convertNumberLikesToNumber(v1);
  const valueB = convertNumberLikesToNumber(v2);

  if (valueA === valueB) return 0;
  return valueA > valueB ? 1 : -1;
}

function formatProspectListForExport({
  id,
  api,
  key,
}: GridValueFormatterParams & {
  key: keyof ListOwnershipDetails;
}) {
  const row = api.getRow(id ?? '');
  if (!row || !row.prospectLists) return '';
  return row.prospectLists
    .map((pl: ListOwnershipDetails) => pl[key])
    .sort((a: string, b: string) => a.localeCompare(b))
    .join(', ');
}

// #endregion

export function industryNames(codes: string[] | undefined) {
  return Array.from(
    new Set(
      codes
        ?.map((code: string) => {
          if (digitsOf(code) < 6) {
            captureMessage('Skipping bad industry code: ' + code, 'warning');
            return undefined;
          }
          return ancestorsAt(code, 2, 4)
            .map(({ title }) => title)
            .join(' → ');
        })
        .filter((it) => it)
    )
  );
}

/**
 * Generates an array of GridColDef objects for rendering columns in a data grid.
 * @returns {CustomGridColDef[]} An array of GridColDef objects representing columns for the data grid.
 */
export function getEDPCols<T extends TableRow>({
  prospectListsMap,
}: GetEDPColsProps): CustomGridColDef<T>[] {
  return [
    {
      field: 'added',
      headerName: 'Date added',
      hideable: false,
      hidden: true,
      type: 'singleSelect',
      valueOptions: [
        DateFilterKind.Today,
        DateFilterKind.LastWeek,
        DateFilterKind.Last30Days,
        DateFilterKind.Last3Months,
        DateFilterKind.ThreePlusMonthsAgo,
      ],
      // custom props for CustomGridColDef
      valueGetterId: 'getDateAddedBuckets',
      filterOperators: getApplyFilterFn<TableRow, string>(
        'is',
        (params, filterItem) => checkRowDate(params.row.dayjs, filterItem.value)
      ),
    },
    {
      field: 'image',
      headerName: '',
      width: 10,
      filterable: false,
      disableExport: true,
      sortable: false,
      resizable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: ({ value, row }) => {
        const name = 'name' in row ? (row.name as string) : row.organization;
        const avatarProps = { sx: { width: 30, height: 30 }, alt: name };

        return value !== EDP_AVATAR_PLACEHOLDER_URL ? (
          <Avatar src={value} {...avatarProps} />
        ) : (
          <Avatar {...avatarProps}>{name[0]}</Avatar>
        );
      },
    },
    {
      field: 'employees_range',
      headerName: 'Company size',
      aggregable: false,
      align: 'center',
      headerAlign: 'center',
      sortComparator: convertToNumberAndSort,
      // custom props for CustomGridColDef
      filterChipSort: convertToNumberAndSort,
      filterChipBlankText: 'None specified',
    },
    {
      field: 'number_of_deals',
      headerName: '# of deals',
      headerAlign: 'center',
      align: 'center',
      resizable: false,
      type: 'number',
      renderHeader: (params) => <BetaHeaderCell<T> {...params} />,
      renderCell: DealCell,
      valueGetter: ({ value }) => {
        return value?.amount ? value.amount : null;
      },
      filterOperators: getApplyFilterFn<TableRow, number | null>(
        'belongsTo',
        checkNumDeals
      ),
      // custom props for CustomGridColDef
      valueGetterId: 'getValueFromObject',
      valueGetterKey: 'amount',
      filterOperatorToUse: 'belongsTo',
      filterChipBlankText: 'No deals',
    },
    {
      field: 'naics_codes',
      width: 170,
      headerName: 'Industry',
      renderCell: ({ value }) => {
        const names = industryNames(value);

        return (
          <Tooltip title={names?.join('\n')}>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Box
                component="p"
                sx={{
                  width: '100%',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {names?.map((name) => (
                  <span>{name}</span>
                ))}
              </Box>
            </Box>
          </Tooltip>
        );
      },
      valueFormatter: ({ value }) => {
        return industryNames(value)?.join('\n');
      },
      filterOperatorToUse: 'naicsCodeOverlaps',
      filterOperators: [naicsCodeOverlaps()],
      filterValueOverrideFn(row) {
        // Use 2-digit and 4-digit ancestor in filter pills
        const value = ('naics_codes' in row ? row.naics_codes : null) ?? [];
        const values = Array.isArray(value) ? value : [value];
        return Array.from(
          new Set(
            values?.flatMap((item: string) =>
              ancestors(item, 2)
                .map(({ code }) => code)
                .filter((code) => digitsOf(code) === 2 || digitsOf(code) === 4)
            )
          )
        );
      },
      getFilterChipLabel: (value) => {
        return allCodeInfos[value as string]?.title ?? '';
      },
    },
    {
      field: 'deal_info.deal_owner_name',
      headerName: 'Deal owners',
      flex: 1,
      minWidth: 125,
      aggregable: false,
      renderHeader: (params) => <BetaHeaderCell<T> {...params} />,
      valueGetter: ({ row }) => {
        if (!row.deal_info || !Array.isArray(row.deal_info)) return null;
        return row.deal_info
          .map((deal) => deal.deal_owner_name)
          .sort((a, b) => a.localeCompare(b))
          .join(', ');
      },
      renderCell: VerticalTypographyCell,
      filterOperators: getApplyFilterFn<TableRow, DealInfo>(
        'belongsTo',
        checkDealInfo
      ),
      // custom props for CustomGridColDef
      valueGetterId: 'getValueFromArrayOfObjects',
      valueGetterKey: 'deal_owner_name',
      filterOperatorToUse: 'belongsTo',
      filterChipBlankText: 'No owner',
    },
    {
      field: 'deal_info.deal_stage',
      headerName: 'Deal stage',
      flex: 1,
      minWidth: 125,
      aggregable: false,
      filterOperators: getApplyFilterFn<TableRow, DealInfo>(
        'belongsTo',
        checkDealInfo
      ),
      renderHeader: (params) => <BetaHeaderCell<T> {...params} />,
      valueGetter: ({ row }) => {
        if (!row.deal_info || !Array.isArray(row.deal_info)) return null;
        return row.deal_info
          .map((deal) => deal.deal_stage)
          .sort((a, b) => a.localeCompare(b))
          .join(', ');
      },
      renderCell: VerticalTypographyCell,
      // custom props for CustomGridColDef
      defaultFilter: true,
      valueGetterId: 'getValueFromArrayOfObjects',
      valueGetterKey: 'deal_stage',
      filterOperatorToUse: 'belongsTo',
      filterChipBlankText: 'No deals',
    },
    {
      field: 'average_deal_size',
      headerName: 'Value',
      minWidth: 110,
      resizable: false,
      aggregable: false,
      templateType: 'currency',
      renderHeader: (params) => <BetaHeaderCell<T> {...params} />,
      renderCell: (params) =>
        params.value !== 0 ? <DealCell {...params} /> : null,
      valueGetter: ({ value }) => value?.amount ?? 0,
      // custom props for CustomGridColDef
      valueGetterId: 'getValueFromObject',
      valueGetterKey: 'amount',
      filterChipBlankText: 'No value',
    },
    {
      field: 'prospectLists.list_uuid',
      headerName: 'Lists',
      flex: 1,
      minWidth: 350,
      sortable: false,
      aggregable: false,
      valueGetter: ({ row }) =>
        row.prospectLists &&
        Array.isArray(row.prospectLists) &&
        row.prospectLists.map(({ list_uuid }) => list_uuid),
      valueFormatter: (params) =>
        formatProspectListForExport({ ...params, key: 'list_name' }),
      renderCell: ({ row }) => (
        <Stack alignItems="center" direction="row" spacing={1}>
          <ChipsList density="compact" objects={row.prospectLists} />
        </Stack>
      ),
      filterOperators: getApplyFilterFn<TableRow, ListOwnershipDetails>(
        'belongsTo',
        checkOwnerOrProspectList
      ), // custom filter operator to check through the array of list UUIDs
      // custom props for CustomGridColDef
      valueGetterId: 'getValueFromArrayOfObjects',
      valueGetterKey: 'list_uuid',
      filterOperatorToUse: 'belongsTo',
      getFilterChipLabel: (value) => {
        if (!value) return '';
        return prospectListsMap.get(value as string)?.name ?? '';
      },
      filterChipBlankText: 'Not in any list',
    },
    {
      field: 'prospectLists.owner_uuid',
      headerName: 'List owner',
      width: 200,
      hidden: true,
      aggregable: false,
      templateType: 'owners',
      valueGetter: ({ row }) => {
        const sorted = row.prospectLists
          .sort((a, b) => a.owner_name.localeCompare(b.owner_name))
          .map((pl) => pl.owner_uuid);
        // return deduped
        return Array.from(new Set(sorted));
      },
      valueFormatter: (params) =>
        formatProspectListForExport({ ...params, key: 'owner_name' }),
      filterOperators: getApplyFilterFn<TableRow, ListOwnershipDetails>(
        'belongsTo',
        checkOwnerOrProspectList
      ), // custom filter operator to check through the array of owner UUIDs
      // custom props for CustomGridColDef
      valueGetterId: 'getValueFromArrayOfObjects',
      valueGetterKey: 'owner_uuid',
      filterOperatorToUse: 'belongsTo',
      filterChipBlankText: 'No list owner',
    },
    {
      field: 'prospectLists.list_type',
      headerName: 'List type',
      hidden: true,
      hideable: false,
      disableExport: true,
      defaultFilter: true,
      valueOptions: Object.values(ListType),
      valueGetter: ({ row }) => {
        return row.prospectLists.map((pl) => pl.list_type);
      },
      filterOperators: getApplyFilterFn<TableRow, ListOwnershipDetails>(
        'belongsTo',
        checkOwnerOrProspectList
      ), // custom filter operator to check through the array of list UUIDs
      // custom props for CustomGridColDef
      valueGetterId: 'getValueFromArrayOfObjects',
      valueGetterKey: 'list_type',
      filterOperatorToUse: 'belongsTo',
      filterChipBlankText: 'Not in any list',
      hideFilterSearchBox: true,
      getFilterChipStartIcon: (value) => {
        const iconName = intelButtonIconNames[value as ListType]; // need to typecast here bc value is unknown
        const Icon = MuiIconManifest[iconName];
        return <Icon fontSize="small" />;
      },
    },
  ];
}
