import { Box, Button } from '@mui/material';
import { useCallback } from 'react';
import { MuiIconManifest } from 'utils/iconManifest';
import { useListBuilder } from './ListBuilderProvider';
import Loader from 'components/common/Svgs/Loader';
import { useWatch } from 'react-hook-form';

export function SaveButton() {
  const { dispatch, previewIsLoading, form } = useListBuilder();

  // Get the current listEntityType from the form
  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });

  const onClick = useCallback(() => {
    dispatch({ type: 'SAVE_LIST_REQUESTED' });
  }, [dispatch]);

  const loaderOpacity = previewIsLoading ? 1 : 0;

  const buttonText =
    listEntityType === 'profiles'
      ? 'Save People List'
      : 'Save Organization List';

  return (
    <>
      <Box
        sx={{
          display: 'inline',
          mr: 3,
          transition: 'opacity 0.1s ease-in-out',
          opacity: loaderOpacity,
        }}
      >
        <Loader />
      </Box>

      <Button
        variant="contained"
        startIcon={<MuiIconManifest.SaveIcon />}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </>
  );
}
