import { WizardStorage } from 'components/Wizard/WizardConfig';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { PreviewListArgs } from 'redux/reducers/api/prospects';
import { ListBuilderFormSchema } from '../formSchema';
import {
  prepareOrganizationOptionsForApi,
  preparePeopleOptionsForApi,
} from '../utils';

export function useRequestBody({
  form,
}: {
  form: UseFormReturn<ListBuilderFormSchema>;
}): PreviewListArgs {
  // Pull values from form state.
  const [
    list_name,
    list_type,
    list_entity_type,
    included_people,
    included_organizations,
    included_job_titles,
    excluded_job_titles,
    included_prospect_lists,
    included_employee_ranges,
    included_naics2022_codes,
    included_company_owners,
    included_contact_owners,
    included_deal_owners,
    included_deal_stages,
  ] = useWatch({
    control: form.control,
    name: [
      'listName',
      'listType',
      'listEntityType',
      'includedPeople',
      'includedOrganizations',
      'includedJobTitles',
      'excludedJobTitles',
      'includedProspectLists',
      'includedEmployeeRanges',
      'includedNaics2022Codes',
      'includedCompanyOwners',
      'includedContactOwners',
      'includedDealOwners',
      'includedDealStages',
    ],
  });

  // Prepares the form values for the API.
  // Map camelCase form values to snake_case API params explicitly, and apply transformations to the values.
  return useMemo(
    () => ({
      params: {
        average_deal_size: 0, // TODO: remove when API no longer requires this.
        list_name,
        list_type: capitalize(list_type),
        list_entity_type: list_entity_type,
        list_source:
          list_entity_type === 'profiles' ? 'People' : 'Organizations',
        result_type: list_entity_type,
        included_people: preparePeopleOptionsForApi(included_people),
        included_organizations: prepareOrganizationOptionsForApi(
          included_organizations
        ),
        included_company_owners,
        included_contact_owners,
        included_deal_owners,
        included_deal_stages,
        included_job_titles,
        excluded_job_titles,
        included_prospect_lists,
        included_employee_ranges,
        included_naics2022_codes,
        wizard_state: {} as WizardStorage,
      },
    }),
    [
      list_name,
      list_type,
      list_entity_type,
      included_people,
      included_organizations,
      included_job_titles,
      excluded_job_titles,
      included_prospect_lists,
      included_employee_ranges,
      included_naics2022_codes,
      included_company_owners,
      included_contact_owners,
      included_deal_owners,
      included_deal_stages,
    ]
  );
}
