export const ListTypes = {
  prospects: 'Prospects (net new leads)',
  pipeline: 'Pipeline (open deals)',
  customers: 'Customers',
  competitors: 'Competitors',
};

export type ListType = keyof typeof ListTypes;

export const ListEntityTypes = {
  profiles: 'People',
  organizations: 'Organizations',
};

export type ListEntityType = keyof typeof ListEntityTypes;

export const listTypeOptions = [
  ...Object.entries(ListTypes).map(([key, value]) => ({
    id: key,
    label: value,
  })),
];

export const OrganizationsInputMethods = {
  crm: 'Import Contacts from CRM',
  describe: 'Describe organizations by attributes',
  // manual: 'Enter manually',
  csv: 'Upload CSV / enter manually',
};

export type OrganizationsInputMethod = keyof typeof OrganizationsInputMethods;

export const PeopleInputMethods = {
  crm: 'Import Contacts from CRM',
  csv: 'Upload CSV / enter manually',
  jobTitles: 'Define personas',
};

export type PeopleInputMethod = keyof typeof PeopleInputMethods;

export const PeopleOrganizationsInputMethods = {
  // list: 'Use existing organization list',
  describe: 'Describe organizations by attributes',
  // crm: 'Import Contacts from CRM',
  csv: 'Upload CSV / enter manually',
};

export type PeopleOrganizationsInputMethod =
  keyof typeof PeopleOrganizationsInputMethods;
