import { Avatar, Box, Tooltip } from '@mui/material';
import { CustomGridColDef } from 'components/common/DataGrid/DataGridProvider';
import {
  ProspectListOrganization,
  ProspectListProfile,
} from 'modules/prospects/types';
import { Link } from 'react-router-dom';
import { industryNames } from '../EDP/table/columns/edpCols';
import { naicsCodeOverlaps } from '@/utils/naics/operators';
import { allCodeInfos, ancestors } from '@/utils/naics';
import { digitsOf } from '@/utils/naics/helpers';

export const commonColumns: CustomGridColDef<
  ProspectListProfile | ProspectListOrganization
>[] = [
  {
    field: 'image',
    headerName: '',
    renderCell: ({ value, row }) => (
      <Avatar alt={row.name} src={value} sx={{ width: 24, height: 24 }} />
    ),
    resizable: false,
    disableColumnMenu: true,
    sortable: false,
    width: 10,
    // custom column definitions not in GridColDef
    filterable: false,
    hideable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ value, row }) => <Link to={row.link}>{value}</Link>,
    flex: 3,
    // custom column definitions not in GridColDef
    filterable: false,
  },
  {
    field: 'events',
    headerName: '# of events',
    flex: 1,
    type: 'number',
    resizable: true,
    // custom column definitions not in GridColDef
    defaultFilter: true,
    valueGetterId: 'sortNumberIntoBuckets',
  },
];

export const profileColumns: CustomGridColDef<
  ProspectListProfile | ProspectListOrganization
>[] = [
  ...commonColumns,
  {
    field: 'speaking',
    headerName: 'Speaking',
    flex: 1,
    resizable: false,
    type: 'number',
    // custom column definitions not in GridColDef
    defaultFilter: true,
    filterable: false,
  },
  {
    field: 'title',
    headerName: 'Title',
    flex: 3,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
  {
    field: 'organization',
    headerName: 'Organization',
    flex: 3,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
];

export const organizationColumns: CustomGridColDef<
  ProspectListProfile | ProspectListOrganization
>[] = [
  ...commonColumns,
  {
    field: 'members',
    headerName: 'Members',
    sortable: true,
    flex: 1,
    type: 'number',
    // custom column definitions not in GridColDef
    defaultFilter: true,
    valueGetterId: 'sortNumberIntoBuckets',
  },
  {
    field: 'location',
    headerName: 'HQ location',
    sortable: true,
    flex: 3,
    // custom column definitions not in GridColDef
    defaultFilter: true,
  },
  {
    field: 'domain',
    headerName: 'Domain',
    sortable: true,
    flex: 3,
    defaultFilter: false,
    renderCell: ({ value }) => (
      <Link to={{ pathname: `https://${value}` }} target="_blank">
        {value}
      </Link>
    ),
  },
  {
    field: 'naics_codes',
    flex: 2,
    headerName: 'Industry',
    sortable: true,
    renderCell: ({ value }) => {
      const names = industryNames(value);

      return (
        <Tooltip title={names?.join('\n')}>
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            <Box
              component="p"
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {names?.map((name) => (
                <span key={name}>{name}</span>
              ))}
            </Box>
          </Box>
        </Tooltip>
      );
    },
    valueFormatter: ({ value }) => {
      return industryNames(value)?.join('\n');
    },
    filterOperatorToUse: 'naicsCodeOverlaps',
    filterOperators: [naicsCodeOverlaps()],
    filterValueOverrideFn(row) {
      // Use 2-digit and 4-digit ancestor in filter pills
      const value = ('naics_codes' in row ? row.naics_codes : null) ?? [];
      const values = Array.isArray(value) ? value : [value];
      return Array.from(
        new Set(
          values?.flatMap((item: string) =>
            ancestors(item, 2)
              .map(({ code }) => code)
              .filter((code) => digitsOf(code) === 2 || digitsOf(code) === 4)
          )
        )
      );
    },
    getFilterChipLabel: (value) => {
      return allCodeInfos[value as string]?.title ?? '';
    },
  },
];
