import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ChooseProspects } from './ChooseProspects';
import { Configure, InstantSearch, Pagination } from 'react-instantsearch-dom';
import {
  setSelectedProspectLists,
  setSelectedProspects,
} from '../../redux/features/search';
import Box from '@mui/material/Box';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Autocomplete } from './Autocomplete';
import { Datepicker } from './Datepicker';
import { ExploreFilterButton } from './ExploreFilterButton';
import { ClearRefinements } from './ClearRefinements';
import { StateResults } from './StateResults';
import {
  createURL,
  INDEX_NAME,
  searchClient,
  searchStateToUrl,
  urlToSearchState,
} from 'utils/algoliaUtils';
import { getTimestamp } from 'utils/date';
import '../../styles/algolia-pagination.scss';
import { addMonths, startOfToday } from 'date-fns/esm';
import { Grid } from '@mui/material';
import { DateRangeExplore, QueryParameters } from './types';
import { SearchState } from 'react-instantsearch-core';
import { useAppSelector } from 'redux/store';

// #region helper functions
export function getDefaultDateFilter() {
  const today = startOfToday();
  return {
    startDate: today,
    endDate: addMonths(today, 3),
    default: true,
  };
}

function getDateFilterOrDefault(dateFilter: DateRangeExplore) {
  if (dateFilter && (dateFilter.startDate || dateFilter.endDate)) {
    return dateFilter;
  }
  return getDefaultDateFilter();
}

function getAlgoliaDateFilterString(realDateFilter: DateRangeExplore) {
  const formattedStartDate = getTimestamp(realDateFilter?.startDate);
  const formattedEndDate = getTimestamp(realDateFilter?.endDate);
  return realDateFilter?.startDate
    ? `date_start:${formattedStartDate} TO ${formattedEndDate}`
    : '';
}

// #endregion

export function AlgoliaSearch() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const searchStateRedux = useAppSelector((state) => state.search);
  const { searchState: urlSearchState, dateFilter } = urlToSearchState(
    location.search
  );
  const [searchState, setSearchState] = useState(urlSearchState);

  function pushToHistoryWithNewSearchState(
    nextSearchState: SearchState,
    optionalQueryParameters: QueryParameters
  ) {
    history.push(
      searchStateToUrl({
        searchState: {
          ...nextSearchState,
          initialLoad: false,
        },
        dateFilter: dateFilter.default ? undefined : dateFilter,
        optionalQueryParameters,
      })
    );
  }

  const onDateFilterChange = (dateFilter: DateRangeExplore) => {
    history.push(
      searchStateToUrl({
        searchState: { ...searchState, initialLoad: false },
        dateFilter,
      })
    );
  };

  const resetFilters = () => {
    dispatch(setSelectedProspects([]));
    dispatch(setSelectedProspectLists([]));
    history.push('/app/events/search');
  };

  const getRealDateFilter = useCallback(() => {
    const result = getDateFilterOrDefault(dateFilter);

    if (searchState.initialLoad && !searchState.query && !result) {
      return getDefaultDateFilter();
    }

    return result;
  }, [dateFilter, searchState.initialLoad, searchState.query]);

  // filter for algolia
  const realDateFilter = getRealDateFilter();
  const dateFilterToApply = getAlgoliaDateFilterString(realDateFilter);
  const filterForAlgolia = [
    dateFilterToApply,
    searchStateRedux.selectedProspects.join(' OR '),
  ]
    .filter((e) => e)
    .join(' AND ');

  return (
    <>
      <Helmet>
        <title>
          {searchState.query
            ? `Event search for "${searchState.query}" - Event explorer - Vendelux`
            : 'Event explorer - Vendelux'}
        </title>
      </Helmet>
      <InstantSearch
        indexName={INDEX_NAME}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={(newSearchState) => {
          pushToHistoryWithNewSearchState(newSearchState, {
            prospect_lists: searchStateRedux.selectedProspectLists,
          });
        }}
        createURL={(searchState) => createURL({ searchState, dateFilter })}
      >
        <Box pb={4}>
          <Autocomplete
            isSearchPage
            initialQuery={searchState.query}
            setSearchState={setSearchState}
            searchState={searchState}
          />
        </Box>

        <Configure
          clickAnalytics
          hitsPerPage={20}
          filters={filterForAlgolia}
          query={searchState.query}
          facets={[
            'attendees.n',
            'sponsors',
            'attendees.t',
            'topics',
            'event_type',
            'sponsors',
            'attendees.n',
            'attendees.t',
            'topics',
            'event_type',
            'country',
            'region',
          ]}
        />

        <Grid sx={{ pb: 4 }} spacing={2} container alignItems="center">
          <Grid item>
            <ExploreFilterButton
              attribute="sponsors"
              label="Sponsors"
              placeholder="Search for sponsor organizations"
              limit={20}
            />
          </Grid>
          <Grid item>
            <ExploreFilterButton
              attribute="attendees.n"
              showMore
              label="Attendees"
              placeholder="Search for attendees"
            />
          </Grid>
          <Grid item>
            <ExploreFilterButton
              attribute="attendees.t"
              showMore
              label="Job titles"
              placeholder="Search for job titles (Director, Co-Founder, etc.)"
            />
          </Grid>
          <Grid item>
            <ExploreFilterButton
              attribute="topics"
              showMore
              label="Topics"
              placeholder="Search for event topics"
            />
          </Grid>
          <Grid item>
            <ExploreFilterButton
              attribute="event_type"
              showMore
              label="Format"
              showSearchBox={false}
              placeholder="Search for event format"
            />
          </Grid>
          <Grid item>
            <ExploreFilterButton
              attribute="country"
              showMore
              label="Country"
              placeholder="Search for country"
            />
          </Grid>
          <Grid item>
            <ExploreFilterButton
              attribute="region"
              showMore
              label="Region"
              placeholder="Search for state, region, or area"
            />
          </Grid>
          <Grid item>
            <Datepicker
              setDateFilter={onDateFilterChange}
              dateFilter={realDateFilter}
            />
          </Grid>
          <Grid item>
            <ChooseProspects
              searchState={searchState}
              pushToHistoryWithNewSearchState={pushToHistoryWithNewSearchState}
            />
          </Grid>
          <Grid item>
            <ClearRefinements
              searchState={searchState}
              dateFilter={dateFilter}
              resetFilters={resetFilters}
            />
          </Grid>
        </Grid>

        <StateResults />

        <Box mt={8} pb={8}>
          <Pagination />
        </Box>
      </InstantSearch>
    </>
  );
}

export default AlgoliaSearch;
