import { Box, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';

export function FormErrorContainer({
  message,
}: {
  message?: string | FieldError;
}) {
  return !!message ? <FormError message={message.toString()} /> : null;
}

export function FormError({ message }: { message: string }) {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography color="error">{message}</Typography>
    </Box>
  );
}
