import { Grid, Stack } from '@mui/material';
import { ListDetailsCard } from 'components/ListBuilder4/Cards/ListDetailsCard';
import { ListContentsCard } from 'components/ListBuilder4/Cards/ListContentsCard';
import { PeopleInputMethodCard } from 'components/ListBuilder4/Cards/PeopleInputMethodCard';
import { PeopleOrganizationsInputMethodCard } from 'components/ListBuilder4/Cards/PeopleOrganizationsInputMethodCard';
import { OrganizationsInputMethodCard } from 'components/ListBuilder4/Cards/OrganizationsInputMethodCard';
import { PreviewCard } from 'components/ListBuilder4/Cards/PreviewCard';
import { ExcludeJobTitlesCard } from 'components/ListBuilder4/Cards/ExcludeJobTitlesCard';
import { OrganizationsIndustryCard } from 'components/ListBuilder4/Cards/OrganizationsIndustryCard';
import { OrganizationsSizeCard } from 'components/ListBuilder4/Cards/OrganizationsSizeCard';
import { useShouldDisplayModule } from './hooks/useModulesToDisplay';
import { CRMListMessageCard } from './Cards/CRMListMessageCard';
export function ListBuilder() {
  const shouldDisplay = useShouldDisplayModule();

  return (
    <Stack spacing={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <ListDetailsCard />

          <ListContentsCard />
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          {shouldDisplay('OrganizationsInputMethodCard') ? (
            <OrganizationsInputMethodCard />
          ) : null}

          {shouldDisplay('PeopleInputMethodCard') ? (
            <PeopleInputMethodCard />
          ) : null}

          {shouldDisplay('ExcludeJobTitlesCard') ? (
            <ExcludeJobTitlesCard />
          ) : null}

          {shouldDisplay('PeopleOrganizationsInputMethodCard') ? (
            <PeopleOrganizationsInputMethodCard />
          ) : null}

          {shouldDisplay('OrganizationsSizeCard') ? (
            <OrganizationsSizeCard />
          ) : null}

          {shouldDisplay('OrganizationsIndustryCard') ? (
            <OrganizationsIndustryCard />
          ) : null}
        </Grid>

        <Grid item xs={12} md={4} lg={5}>
          {shouldDisplay('PreviewCard') ? <PreviewCard /> : null}

          {shouldDisplay('CRMListMessageCard') ? <CRMListMessageCard /> : null}
        </Grid>
      </Grid>
    </Stack>
  );
}
