import { Button, IconButton, useTheme, Box } from '@mui/material';
import { InsertDriveFileOutlined } from '@mui/icons-material';
import {
  ChangeEvent,
  DragEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MuiIconManifest } from 'utils/iconManifest';

export interface FileInputProps {
  onDrop?: (e: DragEvent<HTMLButtonElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

function stopDefaults(e: React.DragEvent) {
  e.stopPropagation();
  e.preventDefault();
}

export function FileInput({ onDrop, onChange }: FileInputProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const buttonBaseStyles = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '50px',
      gap: '10px',
      backgroundColor: theme.vdxColors.vdxGray[300],
    }),
    [theme.vdxColors.vdxGray]
  );

  const uploadedFileStyles = useMemo(
    () => ({
      ...buttonBaseStyles,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      border: `1px solid ${theme.vdxColors.vdxGray[400]}`,
    }),
    [
      buttonBaseStyles,
      theme.palette.text.primary,
      theme.vdxColors.vdxGray,
      theme.palette.common.white,
    ]
  );

  const [buttonStyles, setButtonStyles] = useState(buttonBaseStyles);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleClick = useCallback(() => {
    hiddenFileInput?.current?.click();
  }, []);

  const handleFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        setSelectedFile(e.target.files[0]);
        setButtonStyles(uploadedFileStyles);
      }

      if (onChange) {
        onChange(e);
      }
    },
    [onChange, uploadedFileStyles]
  );

  const handleFileDrop = useCallback(
    async (e: DragEvent<HTMLButtonElement>) => {
      stopDefaults(e);

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        setSelectedFile(e.dataTransfer.files[0]);
        setButtonStyles(uploadedFileStyles);
      }

      if (onDrop) {
        await onDrop(e);
      }
    },
    [onDrop, uploadedFileStyles]
  );

  const handleRemoveFile = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedFile(null);
      setButtonStyles(buttonBaseStyles);
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = '';
      }
    },
    [buttonBaseStyles]
  );

  const dragEvents = {
    onDrop: handleFileDrop,

    onDragEnter: useCallback(
      (e) => {
        stopDefaults(e);
        setButtonStyles((current) => ({
          ...current,
          border: `1px solid ${theme.vdxColors.vdxGray[400]}`,
        }));
      },
      [theme.vdxColors.vdxGray]
    ),

    onDragLeave: useCallback(
      (e) => {
        stopDefaults(e);
        setButtonStyles(selectedFile ? uploadedFileStyles : buttonBaseStyles);
      },
      [buttonBaseStyles, uploadedFileStyles, selectedFile]
    ),

    onDragOver: stopDefaults,
  };

  return (
    <>
      <Button onClick={handleClick} sx={buttonStyles} {...dragEvents}>
        {selectedFile ? (
          <>
            <InsertDriveFileOutlined fontSize="small" color="grey" />
            <Box
              sx={{
                fontWeight: 600,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedFile.name}
            </Box>
            <IconButton
              size="small"
              onClick={handleRemoveFile}
              sx={{ marginLeft: 'auto' }}
            >
              <MuiIconManifest.DeleteIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <>
            <div>
              <MuiIconManifest.CloudUploadIcon fontSize="small" />
            </div>
            <div>Select a .csv or .xlsx file</div>
          </>
        )}
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept=".csv, .xlsx"
        data-testid="file-input"
      />
    </>
  );
}
