import { Stack, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useUserState } from 'hooks/common/useUserState';
import { DealSource } from 'modules/event/types';
import { UpgradeLinkCell } from './UpgradeLinkCell';
import { MuiIconManifest } from 'utils/iconManifest';

function getDealSourceString(source: DealSource) {
  const sourceMap: { [key in DealSource]?: string } = {
    [DealSource.List]: 'prospect list',
    [DealSource.OrgDeal]: 'CRM',
    [DealSource.Team]: 'team settings',
    [DealSource.Default]: 'team settings',
  };
  return `This is an estimate set on the ${sourceMap[source]} page.`;
}

export function DealCell({ formattedValue, value, row }: GridRenderCellParams) {
  const { isEnterpriseUser, isPlusUser, isFreeUser, isProUser } =
    useUserState();

  // show the tooltip if the value is not from the CRM
  const showTooltip = !value?.from_crm;
  const tooltipMessage =
    (isEnterpriseUser && row.avg_deal_size_source !== DealSource.OrgDeal) ||
    isPlusUser
      ? `${getDealSourceString(row.avg_deal_size_source)} ${
          !isEnterpriseUser
            ? 'Upgrade your plan to see ROI information from your CRM.'
            : ''
        }`
      : ``;

  return (
    <Tooltip arrow title={showTooltip ? tooltipMessage : undefined}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {showTooltip && !!formattedValue?.amount && (
          <MuiIconManifest.InfoOutlinedIcon color="inherit" fontSize="small" />
        )}
        {isEnterpriseUser ||
        (isPlusUser && row.average_deal_size && formattedValue !== '') ? (
          <Typography variant="body2">{formattedValue}</Typography>
        ) : (
          <>
            {(isFreeUser ||
              isProUser ||
              (isPlusUser && row.average_deal_size)) && (
              <UpgradeLinkCell
                slotProps={{
                  tooltip: {
                    title:
                      'Upgrade your plan to see ROI information from your CRM.',
                  },
                }}
              />
            )}
          </>
        )}
      </Stack>
    </Tooltip>
  );
}
