import React, { useState } from 'react';
import { AutocompleteRenderGetTagProps, Chip } from '@mui/material';
import { CustomTag } from 'components/ProspectList/AutoCompleteWithUpload/CustomTag';
import { Option } from 'hooks/Prospects/useIcpFilter';
import { formatNumber } from 'utils/util';

export type AutoCompleteTagsProps = {
  value: Option[];
  isLink: boolean;
  getTagProps: AutocompleteRenderGetTagProps;
  allowCsvUpload?: boolean;
  length?: number;
  limitTags?: number;
  handleDelete?: (option: Option, index: number) => void;
};

export const AutoCompleteTags = ({
  value,
  isLink,
  getTagProps,
  allowCsvUpload,
  length,
  limitTags = 100,
  handleDelete,
}: AutoCompleteTagsProps) => {
  const [displayMore, setDisplayMore] = useState(false);

  return (
    <>
      {value.map((option, index) => {
        if (!option.value) return null;

        // Don't display tags beyond the limitTags value, unless we've clicked the chip to display all.
        if (limitTags && index >= limitTags && !displayMore) {
          return null;
        }

        return (
          <CustomTag
            key={index}
            option={option}
            isLink={isLink}
            handleDelete={
              // Use custom handleDelete if provided, otherwise use the default one.
              handleDelete
                ? () => handleDelete(option, index)
                : () => {
                    return getTagProps({ index }).onDelete(undefined);
                  }
            }
          />
        );
      })}

      {!!limitTags && value.length > limitTags && !displayMore ? (
        <Chip
          variant="outlined"
          color="primary"
          label={`+${formatNumber(value.length - limitTags)} more`}
          onClick={() => setDisplayMore(true)}
          sx={{
            cursor: 'pointer',
          }}
        />
      ) : null}

      {!!limitTags && value.length > limitTags && displayMore ? (
        <Chip
          variant="outlined"
          color="primary"
          label={`- show less`}
          onClick={() => setDisplayMore(false)}
          sx={{
            cursor: 'pointer',
          }}
        />
      ) : null}
    </>
  );
};
