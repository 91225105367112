export const endpoints = {
  auth: {
    post: {
      login: '/api/v2/auth/login/',
      logout: '/api/v2/auth/logout/',
      resend_verification_email: '/api/v2/auth/resend_verification_email/',
    },
  },
  task: {
    status: (taskId: string) => `/api/v2/task/${taskId}/status/`,
  },
  events: {
    get: {
      search: (term: any) =>
        `/autocompleteevent?term=${encodeURIComponent(term)}`,
      summary: (eventId: any) => `/api/v1/event/${eventId}/event_summary/`,
      prospects: (eventId: any) => `/api/v2/event/${eventId}/prospects/`,
      attendeeSummaries: (eventId: any) =>
        `/api/v1/event/${eventId}/event_attendee_summaries/`,
      recommendedEvents: (eventId: any) =>
        `/api/v1/event/${eventId}/event_recommended_events/`,
      attendees: (eventId: any) => `/api/v1/event/${eventId}/attendees/`,
      attendeesWeekly: (eventId: any) =>
        `/api/v2/event/${eventId}/weekly_attendees_added/`,
      sponsors: (eventId: any) => `/api/v1/event/${eventId}/sponsors/`,
      edpOrganizations: (eventId: any) =>
        `/api/v1/event/${eventId}/organizations/`,
      subscribeEvent: (eventId: any) =>
        `/api/v1/event/${eventId}/activate_fusion/`, // TODO rename the backend endpoint
      changeAttendance: (eventId: any) =>
        `/api/v1/event/${eventId}/change_attendance/`,
      sponsorshipAlert: (eventId: any) =>
        `/api/v1/event/${eventId}/sponsorship_alert/`,
      latestEventAlert: (eventId: any) =>
        `/api/v1/event/${eventId}/latest_alert/`,
      suggestedAction: (eventId: any) =>
        `/api/v2/event/${eventId}/suggested_action/`,
      enrichEventDownload: (taskId: string) =>
        `/api/v2/enrich_event/${taskId}/download/`,
    },
    post: {
      logCSVDownload: (eventId: any, urlParams: any) =>
        `/api/v2/event/${eventId}/csv_download_detail_log/${
          urlParams ? `?${urlParams}` : ''
        }`,
      enrichAttendees: (eventId: any, urlParams: any) =>
        `/api/v1/event/${eventId}/enrich_event/${
          urlParams ? `?${urlParams}` : ''
        }`,
      evaluateEvent: (eventId: any) =>
        `/api/v1/event/${eventId}/evaluate_event/`,
      uploadLeads: '/api/v1/event/upload_leads/',
      request_event: '/api/v1/event/request',
      custom_event_report: (event: any) =>
        `api/v1/prospects/${event}/export?type=events`,
      data_fix_request: ({ eventId }: any) =>
        `api/v1/event/${eventId}/data_fix_request/`,
    },
  },
  prospects: {
    get: {
      team_lists: '/api/v1/upl/lists/',
      myprospects: '/api/v1/prospects/my_lists/',
      myprospectsShort: (indirect: any) =>
        `/api/v1/prospects/my_lists_short/?indirect=${indirect}`,
      autocomplete_prospect_name: '/autocomplete_prospect_name/',
      view_list_organizations: (uuid: any) =>
        `/api/v1/prospects/${uuid}/view/organizations/`,
      view_list_profiles: (uuid: any) =>
        `/api/v1/prospects/${uuid}/view/profiles/`,
      autocomplete_org: (query: any) =>
        `/api/v1/upl/autocomplete_org/?query=${encodeURIComponent(query)}`,
      v2_autocomplete_organizations: (query: any) =>
        `/api/v2/autocomplete/organization?term=${encodeURIComponent(query)}`,
      v2_autocomplete_profiles: (query: any) =>
        `/api/v2/autocomplete/profile?term=${encodeURIComponent(query)}`,
      v2_get_list: (uuid: any) => `/api/v2/prospects/my_lists/${uuid}/`,
      hydrateList: (uuid: string) => `/api/v2/prospects/${uuid}/view/hydrated/`,
    },
    delete: {
      delete_list: (uuid: any) => `/api/v1/prospects/${uuid}/delete_list/`,
      remove_from_list: (uuid: any) =>
        `/api/v1/prospects/${uuid}/remove_prospect/`,
    },
    put: {
      clone_list: (uuid: any) => `/api/v1/prospects/${uuid}/clone/`,
      add_to_list: (uuid: any) => `/api/v1/prospects/${uuid}/add/`,
      create_list: '/api/v2/prospects/',
    },
    patch: {
      change_roles: (uuid: any) => `/api/v1/prospects/${uuid}/change_roles/`,
      transfer_ownership: (uuid: any) => `/api/v1/prospects/${uuid}/transfer/`,
      follow_org_members: (uuid: any) =>
        `api/v1/prospects/${uuid}/follow_org_members/`,
      rename_list: (uuid: any) => `api/v1/prospects/${uuid}/rename/`,
      update_icp_params: (uuid: any) =>
        `/api/v1/prospects/${uuid}/update_icp_params/`,
      update_wizard_params: (uuid: any) =>
        `/api/v2/prospects/${uuid}/update_wizard_params/`,
      toggle_crm_list_active: (uuid: any) =>
        `/api/v1/prospects/${uuid}/update_crm_list/`,
      deal_values: (uuid: any) => `/api/v2/prospects/${uuid}/deal_values/`,
    },
    post: {
      export: (prospectListUuid: any, type: any) =>
        `api/v1/prospects/${prospectListUuid}/export?type=${type}`,
      enrich: (prospectListUuid: any) =>
        `api/v1/prospects/${prospectListUuid}/enrich`,
      prospect_profiles: '/api/v1/upl/profiles/',
      prospect_orgs: '/api/v1/upl/orgs/',
      preview_profiles_list: '/api/v2/prospects/params/view/profiles/',
      preview_orgs_list: '/api/v2/prospects/params/view/organizations/',
    },
  },
  profile: {
    summary: (uuid: string) => `/ajax/profile_summary/${uuid}/`,
    data: (uuid: string) => `/ajax/profile/${uuid}/`,
    team: (uuid: string) => `/ajax/profile_team/${uuid}/`,
    engagement: (uuid: string) => `/ajax/profile_engagement/${uuid}/`,
    top_topics: (uuid: string) => `/ajax/profile_top_topics/${uuid}/`,
  },
  feed: {
    get: {
      summary: '/ajax/feed_summary/',
      attending: '/ajax/feed_attending/',
      unlocked: '/ajax/feed_unlocked/',
      event_updates: '/api/v2/feed/event_updates/',
    },
  },
  user: {
    get: {
      state: '/api/v1/user_state/',
      capabilities: 'api/v2/user/default/capabilities/',
    },
    delete: {
      impersonating: 'api/v2/user/default/impersonating/',
    },
  },
  industries: '/ajax/industries/', // returns list of industries
  autocomplete: {
    get: {
      companies: (term: any) =>
        `/autocompletecompany/?term=${encodeURIComponent(term)}`,
      topics: (term: any) =>
        `/autocompletetopic/?term=${encodeURIComponent(term)}`,
      prospects: (term: any) =>
        `/autocomplete_prospect_name/?term=${encodeURIComponent(term)}`,
      orgs: (query: any) =>
        `/api/v1/upl/autocomplete_org/?query=${encodeURIComponent(query)}`,
      people: (query: any) =>
        `/api/v1/upl/autocomplete_profile/?query=${encodeURIComponent(query)}`,
    },
  },
  setup: '/ajax/completesetup/',
  teamEvents: {
    index: ({ ownerTeam = 'default' }) =>
      `/api/v1.1/team/${ownerTeam}/team_event/`,
    detail: ({ ownerTeam = 'default', id }: any) =>
      `/api/v1.1/team/${ownerTeam}/team_event/${id}`,
  },
  team: {
    get: {
      members: `/api/v1/team/members/`,
      directory: (teamUuid: any) =>
        `/api/v1/team/directory/${teamUuid ? `${teamUuid}/` : ''}`,
    },
    update: {
      team_data: `/api/v2/team/update_values/`,
    },
  },
  org: {
    get: {
      historical_events: ({ uuid, offset }: any) =>
        `/ajax/org/${uuid}/past/${offset}/`,
    },
  },
  leads: {
    post: {
      upload: `/ajax/leads_upload/`,
      matchFields: `/ajax/leads_match_fields/`,
    },
  },
  planner: {
    get: {
      events: (teamUuid: any) =>
        `/api/v1/planner/events/${teamUuid ? `${teamUuid}/` : ''}`,
      views: '/api/v2/planner/saved_views/',
      columnValue: ({ eventId, columnUuid }: any) =>
        `api/v2/planner/event/${eventId}/column/${columnUuid}/value/`,
    },
    post: {
      uploadCSV: '/api/v1/planner/upload_csv/',
      views: '/api/v2/planner/saved_views/',
      columns: (viewId: any) =>
        `/api/v2/planner/saved_views/${viewId}/saved_columns/`,
    },
    put: {
      columnValue: ({ eventId, columnUuid }: any) =>
        `api/v2/planner/event/${eventId}/column/${columnUuid}/value/`,
    },
    patch: {
      event: '/api/v1/planner/update_event/',
      view: (viewId: any) => `/api/v2/planner/saved_views/${viewId}/`,
      column: ({ viewId, columnUuid }: any) =>
        `/api/v2/planner/saved_views/${viewId}/saved_columns/${columnUuid}/`,
    },
    delete: {
      event: '/api/v1/planner/delete_event/',
      view: (viewId: any) => `/api/v2/planner/saved_views/${viewId}/`,
      column: ({ viewId, columnUuid }: any) =>
        `/api/v2/planner/saved_views/${viewId}/saved_columns/${columnUuid}/`,
      columnValue: ({ eventId, columnUuid }: any) =>
        `api/v2/planner/event/${eventId}/column/${columnUuid}/value/`,
    },
  },

  settings: {
    invitations: {
      list: (teamUuid: any) => `api/v1/team/${teamUuid}/invitation/`,
      single: ({ teamUuid: team_uuid, uuid }: any) =>
        `api/v1/team/${team_uuid}/invitation/${uuid}/`,
      resend: ({ teamUuid, uuid }: any) =>
        `api/v2/team/${teamUuid}/invitation/${uuid}/resend/`,
      accept: ({ uuid, team_uuid }: any) =>
        `api/v1/team/${team_uuid}/invitation/${uuid}/accept/`,
    },
    preferences: `api/v2/team/preferences/`, // GET, PATCH
    put: {
      userRole: (teamUuid: any, profileUuid: any) =>
        `api/v2/team/${teamUuid}/members/${profileUuid}/role/`,
    },
    patch: {
      patchUserPermission: (teamUuid: any) =>
        `api/v1/team/${teamUuid}/members/change_permission/`,
    },
    delete: {
      deleteUser: (profileUuid: any) =>
        `api/v1/settings/${profileUuid}/delete/`,
    },
  },
  crm: {
    get: {
      crmFilters: (teamUuid: any) => `api/v2/crm/${teamUuid}/filters/`,
    },
  },

  reports: {
    list: `api/v2/event_reports/`,
    single: (uuid: any) => `api/v2/event_reports/${uuid}/`,
    eventList: (uuid: any) => `api/v2/event_reports/${uuid}/events/`,
  },

  industry: {
    get: {
      subindustries: 'api/v2/industry/taxonomy/subindustries/',
      industries: 'api/v2/industry/taxonomy/industries/',
      industryGroups: 'api/v2/industry/taxonomy/industry_groups/',
      sectors: 'api/v2/industry/taxonomy/sectors/',
    },
  },

  gdpr: {
    forgottenContacts: 'api/v2/privacy/forgotten_contacts/',
  },
};
