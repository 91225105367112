import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { useDebounceValue } from 'usehooks-ts';
import { zodResolver } from '@hookform/resolvers/zod';
import { PreviewListArgs, prospectsApi } from 'redux/reducers/api/prospects';
import {
  defaultListBuilderFormValues,
  ListBuilderFormSchema,
  listBuilderFormSchema,
} from './formSchema';
import {
  listBuilderInitialState,
  ListBuilderAction,
  listBuilderReducer,
  ListBuilderState,
} from './listBuilderReducer';
import { useRequestBody } from './hooks/useRequestBody';
import { usePosthog } from 'analytics';
import { useSnacks } from 'hooks/common/useSnacks';
import { useHistory } from 'react-router';
import { checkIsCrmList, prepareListForApi } from './utils';
import * as Sentry from '@sentry/react';
import { useListBuilderAnalytics } from 'components/ListBuilder4/hooks/useListBuilderAnalytics';
// Create the context
const ListBuilderContext = createContext<{
  state: ListBuilderState;
  dispatch: React.Dispatch<ListBuilderAction>;
  form: UseFormReturn<ListBuilderFormSchema>;
  previewIsLoading?: boolean;
  initialState: ListBuilderState;
  initialFormValues: ListBuilderFormSchema;
  mode: 'create' | 'update';
  listUuid?: string;
} | null>(null);

// Create the provider component
interface ListBuilderProviderProps {
  listUuid?: string;
  children: ReactNode;
  initialState?: ListBuilderState;
  initialFormValues?: ListBuilderFormSchema;
}

export function ListBuilderProvider({
  listUuid,
  children,
  initialState = listBuilderInitialState,
  initialFormValues = defaultListBuilderFormValues,
}: ListBuilderProviderProps) {
  const [state, dispatch] = useReducer(listBuilderReducer, initialState);
  const { sendPosthogEvent } = usePosthog();
  const { showErrorSnack } = useSnacks();
  const history = useHistory();

  const form = useForm({
    defaultValues: initialFormValues,
    resolver: zodResolver(listBuilderFormSchema),
  });

  // Pull values from form state using a single consolidated useWatch call
  const [
    listEntityType,
    listType,
    includedJobTitles,
    includedOrganizations,
    includedNaics2022Codes,
    includedEmployeeRanges,
  ] = useWatch({
    control: form.control,
    name: [
      'listEntityType',
      'listType',
      'includedJobTitles',
      'includedOrganizations',
      'includedNaics2022Codes',
      'includedEmployeeRanges',
    ],
  });

  // Prepares the form values for the API.
  // Map camelCase form values to snake_case API params explicitly, and apply transformations to the values.
  const requestBody: PreviewListArgs = useRequestBody({ form });
  const [debouncedRequestBody] = useDebounceValue(requestBody, 500);

  // Determine if we should skip fetching the preview for special list types
  const isSpecialListType = ['customers', 'competitors', 'prospects'].includes(
    listType as string
  );
  const hasJobTitles =
    Array.isArray(includedJobTitles) && includedJobTitles.length > 0;
  const hasOrganizations =
    (Array.isArray(includedOrganizations) &&
      includedOrganizations.length > 0) ||
    (Array.isArray(includedNaics2022Codes) &&
      includedNaics2022Codes.length > 0) ||
    (Array.isArray(includedEmployeeRanges) &&
      includedEmployeeRanges.length > 0);

  // For special list types with profiles, we need both job titles and organizations
  const shouldSkipProfilesFetch =
    isSpecialListType &&
    listEntityType === 'profiles' &&
    (!hasJobTitles || !hasOrganizations);

  // #region API calls

  // Fetch the preview data.
  const { data: previewProfilesData, ...previewProfilesResult } =
    prospectsApi.useGetProfilesListPreviewQuery(debouncedRequestBody, {
      skip: listEntityType !== 'profiles' || shouldSkipProfilesFetch,
    });

  const { data: previewOrganizationsData, ...previewOrganizationsResult } =
    prospectsApi.useGetOrganizationsListPreviewQuery(debouncedRequestBody, {
      skip: listEntityType !== 'organizations',
    });

  const [createListMutation] = prospectsApi.usePutCreateListMutation();
  const [updateWizardParamsMutation] =
    prospectsApi.usePatchUpdateWizardParamsMutation();
  const [patchCrmList] = prospectsApi.usePatchCrmListMutation();

  // #endregion

  // Loading state.
  const previewIsLoading =
    (listEntityType === 'organizations' &&
      (previewOrganizationsResult.isLoading ||
        previewOrganizationsResult.isFetching)) ||
    (listEntityType === 'profiles' &&
      (previewProfilesResult.isLoading || previewProfilesResult.isFetching));

  const mode = listUuid ? 'update' : 'create';

  // Use the analytics hook to track form state changes
  useListBuilderAnalytics(form);

  // #region Functions

  const handleApiError = useCallback(
    (message: string = 'unknown error') => {
      Sentry.captureException(`Error creating list: ${message}`);
      console.error('Error creating list:', message);
      showErrorSnack(`Error creating list: ${message}`);
      return;
    },
    [showErrorSnack]
  );

  const handleCreate = useCallback(
    async (formValues: ListBuilderFormSchema, state: ListBuilderState) => {
      const { listName, listType, listEntityType } = formValues;

      try {
        const createParams = prepareListForApi(formValues);
        const response = await createListMutation(createParams);
        const isCrmList = checkIsCrmList(formValues, state);

        // NOTE: all the ts expect errors are due to the fact that the response type is not fully typed.

        // @ts-expect-error
        const error = response.error?.data?.message;
        // @ts-expect-error
        const success = response?.data?.success;
        // @ts-expect-error
        const message = response.data?.message;

        // Indicates an API error. This will be handled by the onQueryStarted callback in the putCreateList mutation.
        // See redux/reducers/api/prospects.ts.
        if (error) return;

        if (!success) throw new Error(message);

        // @ts-expect-error
        const { uuid } = response.data.results;

        if (isCrmList) {
          await patchCrmList({
            uuid,
            crm_list: true,
          });
        }

        history.push(`/app/myprospects/lists`);

        // Send the new "List Created" event with updated format
        sendPosthogEvent('List Builder > List Created', {
          listName,
          listType,
          listUuid: uuid,
          listContents: listEntityType,
        });
      } catch (error) {
        handleApiError(error as string);
        return;
      }
    },
    [
      createListMutation,
      handleApiError,
      history,
      patchCrmList,
      sendPosthogEvent,
    ]
  );

  const handleUpdate = useCallback(
    async (formValues: ListBuilderFormSchema, state: ListBuilderState) => {
      const { listName, listType, listEntityType } = formValues;

      if (!listUuid) {
        throw new Error('List UUID is required.');
      }

      try {
        const updateParams = {
          ...prepareListForApi(formValues),
          uuid: listUuid,
        };
        const response = await updateWizardParamsMutation(updateParams);
        const isCrmList = checkIsCrmList(formValues, state);

        // @ts-expect-error
        const error = response.error?.data?.message;
        // @ts-expect-error
        const success = response?.data?.success;
        // @ts-expect-error
        const message = response.data?.message;

        // Indicates an API error. This will be handled by the onQueryStarted callback in the putCreateList mutation.
        // See redux/reducers/api/prospects.ts.
        if (error) return;

        if (!success) throw new Error(message);

        if (isCrmList) {
          await patchCrmList({
            uuid: listUuid,
            crm_list: true,
          });
        }

        history.push(`/app/myprospects/lists`);

        // Send the new "List Updated" event with updated format
        sendPosthogEvent('List Builder > List Updated', {
          listName,
          listType,
          listUuid,
          listContents: listEntityType,
        });
      } catch (error) {
        handleApiError(error as string);
        return;
      }
    },
    [
      handleApiError,
      history,
      listUuid,
      patchCrmList,
      sendPosthogEvent,
      updateWizardParamsMutation,
    ]
  );

  const onSubmit = useCallback(
    async (values: any) => {
      if (mode === 'create') {
        await handleCreate(values, state);
      } else {
        await handleUpdate(values, state);
      }
    },
    [handleCreate, handleUpdate, mode, state]
  );

  const saveList = useCallback(async () => {
    form.handleSubmit(onSubmit)();
  }, [form, onSubmit]);

  // #endregion

  // #region Effects
  useEffect(() => {
    if (previewProfilesData) {
      dispatch({
        type: 'SET_PEOPLE',

        people: previewProfilesData?.profiles,
      });
    }
  }, [previewProfilesData, dispatch]);

  useEffect(() => {
    if (previewOrganizationsData) {
      dispatch({
        type: 'SET_ORGANIZATIONS',

        organizations: previewOrganizationsData?.organizations,
      });
    }
  }, [previewOrganizationsData, dispatch]);

  useEffect(() => {
    if (state.saveListRequested === true) {
      dispatch({ type: 'CLEAR_SAVE_LIST_REQUEST' });
      saveList();
    }
  }, [dispatch, state.saveListRequested, saveList]);

  // #endregion

  return (
    <ListBuilderContext.Provider
      value={{
        state,
        dispatch,
        form,
        previewIsLoading,
        initialState,
        initialFormValues,
        mode,
        listUuid,
      }}
    >
      {children}
    </ListBuilderContext.Provider>
  );
}

// Create a custom hook for using the context
export function useListBuilder() {
  const context = useContext(ListBuilderContext);
  if (!context) {
    throw new Error('useListBuilder must be used within a ListBuilderProvider');
  }
  return context;
}
