import { ThunkDispatch } from '@reduxjs/toolkit';
import { QueryFulfilledRejectionReason } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ApiResponse, isApiError } from 'utils/api-response';
import { setSnacksQueue } from '../snacks';
import { AppDispatch } from 'redux/store';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import { PromiseWithKnownReason } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';

// this is a type that represents the result of a query that has been fulfilled by RTK Query
export type queryFulfilled<T> = PromiseWithKnownReason<
  {
    data: T;
    meta: FetchBaseQueryMeta | undefined;
  },
  QueryFulfilledRejectionReason<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >
  >
>;

interface ReportResultInSnackProps<T> {
  dispatch: ThunkDispatch<any, any, any>;
  queryFulfilled: T;
  defaultErrorMessage: string;
  defaultSuccessMessage?: string; // if doesn't exist then don't show success toast
  forceSuccess?: boolean;
  /**
   * Timeout for the error message. If not provided, it will use the default timeout.
   * @see {@link setSnacksQueue}
   */
  errorTimeout?: number;
  /**
   * Timeout for the success message. If not provided, it will use the default timeout.
   * @see {@link setSnacksQueue}
   */
  successTimeout?: number;
}

interface ShowErrorInSnackParams {
  error: unknown;
  defaultErrorMessage: string;
  dispatch: AppDispatch;
  timeout?: number;
}

export function showErrorInSnack({
  error,
  defaultErrorMessage,
  dispatch,
  timeout,
}: ShowErrorInSnackParams) {
  const message =
    isApiError(error) && error?.data?.message
      ? error.data.message
      : defaultErrorMessage;
  dispatch(
    setSnacksQueue({
      message,
      timeout,
    })
  );
}

/**
 * Triggers a snack message for the result of `queryFulfilled`. On success, dispatches a
 * success message, optionally overridden by `forceSuccess`. On failure, dispatches an
 * error message.
 */
export function reportResultInSnack<T extends Promise<any>>({
  dispatch,
  queryFulfilled,
  defaultSuccessMessage = '',
  defaultErrorMessage,
  forceSuccess = false,
  errorTimeout,
  successTimeout,
}: ReportResultInSnackProps<T>) {
  return queryFulfilled
    .then(({ data }) => {
      if (forceSuccess || data?.success) {
        if (defaultSuccessMessage) {
          dispatch(
            setSnacksQueue({
              message: data?.message ? data.message : defaultSuccessMessage,
              type: 'success',
              timeout: successTimeout, // pass successTimeout here
            })
          );
        }
        return queryFulfilled;
      }
      return Promise.reject({
        error: {
          data,
        },
      });
    })
    .catch((reason: QueryFulfilledRejectionReason<BaseQueryFn>) => {
      showErrorInSnack({
        error: reason.error,
        defaultErrorMessage: defaultErrorMessage,
        dispatch: dispatch,
        timeout: errorTimeout,
      });
    });
}

/**
 * Extracts and returns results from an API response, or throws a TypeError
 * if resp.success is false (successfully got an error returned from the API).
 */
export function extractResults<TResults>(
  resp: ApiResponse<TResults>
): TResults {
  if (!resp.success) throw new TypeError(resp.message);
  return resp.results;
}
