import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/css';
import { useTheme, Theme } from '@mui/material/styles';
import { Stack, Tooltip } from '@mui/material';
import { ClickableCard } from 'components/Prospects/ClickableCard';
import { defaultStyles } from 'utils/theme';
import { ListOwnershipDetails } from 'hooks/EDP/useProspects';
import { ProspectListShort } from 'adapters/types';

interface ChipsListSubMenuProps {
  objects: ListOwnershipDetails[];
  keyPrefix?: string;
  myProspectListsMap: Map<string, ProspectListShort>;
}

// #region styles

const MoreButtonPopperStyle = (theme: Theme) => css`
  max-height: 300px;
  overflow: auto;
  border: 1px solid ${theme.palette.divider};
  border-radius: ${defaultStyles.borderRadius};
  box-shadow: ${defaultStyles.boxShadow};
`;

const MoreButtonTooltipStyle = (theme: Theme) => css`
  background-color: transparent;
  opacity: 1 !important;
  cursor: pointer;
  margin: 0 !important;
  padding: 0;
  .MuiPaper-root:not(:first-child) {
    border-top: 1px solid ${theme.palette.divider};
  }
  .MuiPaper-root {
    border: 0;
    border-radius: 0;
  }
`;

// #endregion

export const ChipsListSubMenu = ({
  objects,
  children,
  keyPrefix = '',
  myProspectListsMap,
}: PropsWithChildren<ChipsListSubMenuProps>) => {
  const theme = useTheme();

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          className: MoreButtonTooltipStyle(theme),
        },
        popper: {
          className: MoreButtonPopperStyle(theme),
        },
      }}
      title={objects.map((object, index) => (
        <ClickableCard
          key={`${keyPrefix}-overflow-card-${index}`}
          title={object.list_name}
          subheader={object.owner_name}
          filterable={Boolean(myProspectListsMap.get(object.list_uuid))}
          url={''}
        />
      ))}
    >
      <Stack>{children}</Stack>
    </Tooltip>
  );
};
