import React, { ComponentProps } from 'react';
import { Divider, Stack, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { useUserState } from 'hooks/common/useUserState';
import { UpgradeLinkCell } from './UpgradeLinkCell';

// DEV-3145: placeholder UI for multiple values, will be changed in the future
export function VerticalTypographyCell({
  value,
  slotProps,
  row,
}: GridRenderCellParams & {
  slotProps?: {
    stack?: ComponentProps<typeof Stack>;
    typography?: ComponentProps<typeof Typography>;
    divider?: ComponentProps<typeof Divider>;
  };
}) {
  const { isFreeUser, isProUser, isPlusUser } = useUserState();
  if (isFreeUser || isProUser || (isPlusUser && row.average_deal_size))
    return (
      <UpgradeLinkCell
        slotProps={{
          tooltip: {
            title: 'Upgrade your plan to see ROI information from your CRM.',
          },
        }}
      />
    );

  if (!value) return null;

  const values: string[] = Array.from(new Set(value.split(', ')));

  return (
    <Stack
      {...slotProps?.stack}
      sx={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
      }}
      alignItems="flex-start"
      spacing={1}
    >
      <Tooltip title={values.join(', ')}>
        <Typography
          component="span"
          variant="body2"
          sx={{
            display: 'inline-block',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          {...slotProps?.typography}
        >
          {values.join(', ')}
        </Typography>
      </Tooltip>
    </Stack>
  );
}
