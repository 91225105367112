import React from 'react';
import { DateRangeExplore } from 'components/Explore/types';
import { useUserState } from 'hooks/common/useUserState';
import { getDefaultDateFilter } from './AlgoliaSearch';
import { ButtonDateRangePicker } from 'components/common/Buttons/ButtonDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

interface DatepickerProps {
  setDateFilter: (range: DateRangeExplore) => void;
  dateFilter: DateRangeExplore;
}

const formatForExplorePage = ([
  startDate,
  endDate,
]: DateRange<Dayjs>): DateRangeExplore => {
  if (startDate && endDate)
    return {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      default: false,
    };
  else {
    return getDefaultDateFilter();
  }
};

const formatForDateRangePicker = (
  dateFilter: DateRangeExplore
): DateRange<Dayjs> => {
  return [dayjs(dateFilter.startDate), dayjs(dateFilter.endDate)];
};

export const Datepicker = ({ setDateFilter, dateFilter }: DatepickerProps) => {
  const { isAuthenticated, isFreeUser } = useUserState();

  const disabled = !isAuthenticated || isFreeUser;
  const [startDate, endDate] = formatForDateRangePicker(dateFilter);

  return (
    <ButtonDateRangePicker
      disabled={disabled}
      handleApply={(range) => {
        setDateFilter({
          ...formatForExplorePage(range),
          default: false,
        });
      }}
      existingDate={{
        startDate,
        endDate,
      }}
      slotProps={{
        button: {
          disableChevron: true,
          disablePrependedDateLabel: true,
        },
      }}
    />
  );
};
