import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Typography } from '@mui/material';
import { useUserState } from 'hooks/common/useUserState';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormErrorContainer } from '../FormError';
import { useEffect } from 'react';
import { CSVColumnsRow } from './CSVColumnsRow';
import { useCSVUploadContext } from './CSVUploadContext';

export const formSchema = z.object({
  email: z.string(),
  uuid: z.string().optional(),
});

const defaultValues = {
  email: '',
  uuid: '',
};

export type PeopleCSVColumnsFormSchema = z.infer<typeof formSchema>;

export interface PeopleCSVColumnsFormProps {
  columns: string[];
  onColumnSelected?: (fieldName: string, column: string) => void;
}

export function PeopleCSVColumnsForm() {
  const { columns, setSelectedColumn, setSelectedColumnType } =
    useCSVUploadContext();
  return (
    <PeopleCSVColumnsFormUI
      columns={columns}
      onColumnSelected={(name, value) => {
        setSelectedColumnType(name);
        setSelectedColumn(value);
      }}
    />
  );
}

export function PeopleCSVColumnsFormUI({
  columns,
  onColumnSelected,
}: PeopleCSVColumnsFormProps) {
  const { isVendeluxEmployee } = useUserState();

  const form = useForm<PeopleCSVColumnsFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  // When a column is selected, let the parent component know what field was matched with which column.
  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (onColumnSelected && name) {
        onColumnSelected(name, value[name] as string);
      }
    }, defaultValues);
    return () => subscription.unsubscribe();
  }, [form.watch, form, onColumnSelected]);

  return (
    <Box data-testid="people-csv-columns-form">
      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
        Select column
      </Typography>

      <CSVColumnsRow<PeopleCSVColumnsFormSchema>
        columns={columns}
        form={form}
        fieldName="email"
        label="Email"
      />
      <FormErrorContainer message={form.formState.errors.email} />

      {isVendeluxEmployee ? (
        <CSVColumnsRow<PeopleCSVColumnsFormSchema>
          columns={columns}
          form={form}
          fieldName="uuid"
          label="UUID"
        />
      ) : null}
      <FormErrorContainer message={form.formState.errors.uuid} />
    </Box>
  );
}
