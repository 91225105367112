import { Dayjs } from 'dayjs';
import { GridColDef } from '@mui/x-data-grid-premium';
import { EDPProspectList } from 'adapters/types';
import { ListOwnershipDetails } from 'hooks/EDP/useProspects';

export const enum TableTypesKind {
  Attendee = 'attendee',
  Organization = 'organization',
}

// #region Table types
export type TableAttendees = DataTable<
  TableRowAttendee,
  TableTypesKind.Attendee
>;

export type TableOrganizations = DataTable<
  TableRowOrganization,
  TableTypesKind.Organization
>;

export interface TableBase {
  columns: GridColDef[];
  loading: boolean;
  authorized: boolean;
  count: number;
}

export interface DataTable<
  TRow extends TableRowBase,
  TType extends string = string
> extends TableBase {
  data: TRow[];
  type: TType;
}

// #endregion

// #region Table Row types

export interface TableRow extends TableRowBase {}
export interface TableRowBase {
  id: string; // for data grid
  organization: string;
  added: string; // date added to our databases
  image: string;
  prospect: boolean;
  dayjs: Dayjs;
  status: StatusFilterKind;
  prospectLists: ListOwnershipDetails[];
  deal_info: DealInfo[];
  number_of_deals: {
    amount: number;
    from_crm: boolean;
  };
  average_deal_size: {
    amount: number;
    from_crm: boolean;
  };
  avg_deal_size_source: DealSource;
  naics_codes: string[] | null;
}

export interface DealInfo {
  deal_id: number;
  deal_owner_id: number;
  deal_owner_name: string;
  deal_stage: string;
}

export enum DealSource {
  List = 'LIST',
  Team = 'TEAM',
  OrgDeal = 'ORG_DEAL',
  Default = 'DEFAULT',
}

export type EmployeesRange =
  | ''
  | '0-10'
  | '11-50'
  | '51-250'
  | '251-1K'
  | '1K-5K'
  | '5K-10K'
  | '10K-50K'
  | '50K-100K'
  | '100K+';
export interface TableRowAttendee extends TableRowBase {
  title: string;
  jobtitle: string;
  name: string;
  org_slug: string;
  org_uuid: string;
  person_url: string;
  profile_slug: string;
  profile_uuid: string;
  speaking: boolean;
  status: StatusFilterKind;
  is_sponsor: boolean;
  type: TableTypesKind.Attendee;
  cost: number; // only attendees cost $ to enrich
  employees_range: EmployeesRange;
  profile_city: string;
  profile_region: string;
  profile_country: string;
}
export interface TableRowOrganization extends TableRowBase {
  events: number;
  members: number;
  slug: string;
  uuid: string;
  website: string;
  type: TableTypesKind.Organization;
  sponsoring_confirmed: boolean;
  sponsoring_predicted: boolean;
  sponsoring_historical: boolean;
}

export type SelectedFilterMap = Map<string, boolean>;

// #endregion

export type ProspectListMap = Map<string, EDPProspectList>; // map of Prospect List UUID -> ProspectList

export enum SpeakerFilterKind {
  Neither = 'neither',
  Speaking = 'speaking',
  NotSpeaking = 'not speaking',
}
export enum StatusFilterKind {
  None = 'None',
  Predicted = 'Predicted',
  Confirmed = 'Confirmed',
  Historical = 'Historical',
}

export enum DateFilterKind {
  ThreePlusMonthsFromNow = '3+ months from now',
  Next3Months = 'Next 3 months',
  Next30Days = 'Next 30 days',
  NextWeek = 'Next 7 days',
  Today = 'Today',
  LastWeek = 'Last 7 days',
  Last30Days = 'Last 30 days',
  Last3Months = 'Last 3 months',
  ThreePlusMonthsAgo = '3+ months',
  None = 'None',
}

export enum EDPTabKind {
  Attendees = 'Attendees',
  Organizations = 'Organizations',
  About = 'About',
}

export const attendeesStatusFilter = {
  id: 'status',
  field: 'status',
  operator: 'isAnyOf',
  value: [StatusFilterKind.Confirmed, StatusFilterKind.Predicted],
};

export const organizationsStatusFilter = {
  id: 'sponsoringStatus',
  field: 'sponsoringStatus',
  operator: 'isAnyOf',
  value: [StatusFilterKind.Confirmed, StatusFilterKind.Predicted],
};
