import { api } from './index';
import { strings } from '../../../variables/messages';
import { endpoints } from 'variables/endpoint-urls';
import { ApiResponse, ApiResponseBase } from 'utils/api-response';
import { extractResults, reportResultInSnack } from './util';
import {
  ListEntityType,
  ListType,
  ProspectList,
  ProspectListShort,
} from 'adapters/types';
import { GridSortDirection } from '@mui/x-data-grid-premium';
import {
  Role,
  ProspectListICP,
  ProspectListProfile,
  ProspectListOrganization,
  TeamMembersWithPermissions,
} from 'modules/prospects/types';
import {
  ListWizardWizardParams,
  WizardListParams,
} from 'components/Wizard/WizardConfig';

// #region types

export type ExportType = 'list' | 'events';

interface GetMyProspectListsShortResponse {
  prospect_lists: ProspectListShort[];
}
interface GetMyProspectListsResponse {
  prospect_lists: ProspectList[];
}
interface PostProspectListExportResponse {
  data: {
    excel_file: string;
  };
}

interface PatchChangeRolesResponse {
  shared_with: TeamMembersWithPermissions[];
  change_messages: {
    email: string;
    role: string;
    success: boolean;
    message: string;
  }[];
}
export interface PutCreateListResponse {
  name: string;
  uuid: string;
  link: string;
  owner: {
    uuid: string;
    name: string;
    email: string;
    role: string;
    profile: string;
    avatar: string;
  };
  smart_list: boolean;
  icp_params?: Record<string, any>;
  wizard_params?: Record<string, any>;
  list_type: string;
  list_source: string;
  list_entity_type: string;
}
interface GetTeamProspectListsResponse {
  results: ProspectListFromAPI[];
}

export interface GetAutocompleteOrgs {
  orgs: {
    organization_uuid: string;
    organization_name: string;
    count: number;
  }[];
}
export interface GetAutocompletePeople {
  profiles: {
    match_field: string;
    profile_uuid: string;
    profile_name: string;
    profile_title: string;
    organization_name: string;
    count: number;
  }[];
}

export namespace V2 {
  export type GetAutocompleteOrganizationsResponsePayload = {
    uuid: string;
    name: string;
    static_slug: string;
  }[];

  export type GetAutocompleteProfilesResponsePayload = {
    uuid: string;
    name: string;
    static_slug: string;
    organization__name: string;
  }[];
}

interface AutocompleteOption {
  uuid: string;
  name: string;
  type: 'org' | 'profile';
}

interface PostProspectsBody {
  search: string[] | '' | null;
  owners: string[];
  lists: string[];
  sort_column: string | null;
  sort_direction: GridSortDirection;
  limit: number;
  offset: number;
}

export type ProspectListFromAPI = {
  is_shared: boolean;
  list_name: string;
  list_uuid: string;
  owner_name: string;
  owner_uuid: string;
};

// need to remove 'id' from MyProspect because it's not in the API response
export type ProspectProfileFromAPI = {
  profile_uuid: string;
  profile_name: string;
  profile_title: string;
  profile_image: string;
  profile_slug: string;
  organization_uuid: string;
  organization_name: string;
  organization_slug: string;
  owners: string; // string that needs to be split by comma
  lists: string; // string that needs to be split by comma
};

export type ProspectOrgFromAPI = {
  organization_image: string;
  organization_name: string;
  organization_slug: string;
  organization_uuid: string;
  owners: string;
  lists: string;
};

export type CreateListArgs = {
  listName: string;
  icpBuilder: boolean;
  listType: string;
  listSource: string;
  listEntityType: ListEntityType;
  icpParams: Record<string, any> | null;
  wizardParams: WizardListParams;
};

export type PreviewListArgs = {
  params: Omit<ListWizardWizardParams, 'result_type'> & {
    average_deal_size: number;
    list_name: string;
    list_type: string;
    list_source: 'People' | 'Organizations';
    list_entity_type: 'profiles' | 'organizations';
    result_type: 'organizations' | 'profiles';
    wizard_state: {};
  };
};

export interface Profile {
  domain: string;
  events: number;
  image: string;
  link: string;
  naics_codes: string[];
  name: string;
  organization: string;
  speaking: number;
  title: string;
  uuid: string;
}

export interface Organization {
  domain: string;
  events: number;
  image: string;
  industry: string;
  link: string;
  location: string;
  members: number;
  naics_codes: string[];
  name: string;
  uuid: string;
}

export interface GetOrganizationsListPreviewResponse {
  organizations: ProspectListOrganization[];
}

export type GetOrganizationsListPreviewArgs = PreviewListArgs;

export interface GetProfilesListPreviewResponse {
  profiles: ProspectListProfile[];
}

export interface GetListResponse {
  uuid: string;
  name: string;
  follow_org_members: boolean;
  has_icp_params: boolean;
  smart_list: boolean;
  crm_list: boolean;
  smart_list_updated_at: string;
  smart_list_last_operation: string;
  icp_params_updated_at: string;
  edp_matching_done_at: string;
  smart_list_status: string;
  list_type: ListType;
  list_source: string;
  list_entity_type: ListEntityType;
  created: string;
  icp_params: null;
  link: string;
  modified: string;
  my_permissions: ('Read' | 'Write' | 'Owner' | 'Rename' | 'Delete')[];
  owner: {
    uuid: string;
    name: string;
    email: string;
    role: string;
    profile: string;
    avatar: string;
  };
  shared_with: [];
  wizard_params: ListWizardWizardParams;
}

export type GetProfilesListPreviewArgs = PreviewListArgs;

export type HydratedProfileOrOrganization = {
  uuid: string;
  name: string;
};
export type GetHydrateListResponse = {
  profiles?: HydratedProfileOrOrganization[];
  orgs?: HydratedProfileOrOrganization[];
};

// #endregion

const successMessages = strings.prospects.success;
const errorMessages = strings.prospects.error;

export const prospectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Query function to retrieve prospect lists with an optional option to include prospects.
     * @returns {ApiResponseBase<GetMyProspectListsResponse>} - An API response containing prospect lists.
     */
    getMyProspectLists: builder.query<
      ApiResponseBase<GetMyProspectListsResponse>,
      void
    >({
      query: () => ({
        url: endpoints.prospects.get.myprospects,
        method: 'GET',
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.myprospects,
        });
      },
      providesTags: (response) =>
        response?.success
          ? [
              'ProspectLists',
              ...response.prospect_lists.map((list) => ({
                type: 'ProspectLists' as const,
                uuid: list.uuid,
              })),
            ]
          : ['ProspectLists'],
    }),

    /**
     * Query function to retrieve prospect lists for algolia explore page
     */
    getMyProspectListsShort: builder.query<
      ApiResponseBase<GetMyProspectListsShortResponse>,
      boolean
    >({
      query: (indirect) => ({
        url: endpoints.prospects.get.myprospectsShort(indirect),
        method: 'GET',
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.myprospects,
        });
      },
    }),

    /**
     * Query function to retrieve a single list by UUID.
     */
    getList: builder.query({
      query: (uuid) => ({
        url: endpoints.prospects.get.v2_get_list(uuid),
        method: 'GET',
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.myprospects,
        });
      },
      providesTags: (response) => [
        {
          type: 'ProspectLists',
          uuid: response.uuid,
        },
      ],
    }),

    /**
     * Query function to retrieve the profiles in a prospect list.
     */
    getProspectListProfiles: builder.query<
      ApiResponseBase<
        ProspectListICP & {
          profiles: ProspectListProfile[];
        }
      >,
      string
    >({
      query: (uuid) => ({
        url: endpoints.prospects.get.view_list_profiles(uuid),
        method: 'GET',
      }),
      providesTags: ['ProspectLists'],
      onQueryStarted: (uuid, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.view_list_profiles(uuid),
        });
      },
    }),

    /**
     * Query function to retrieve the organizations in a prospect list.
     */
    getProspectListOrganizations: builder.query<
      ApiResponseBase<
        ProspectListICP & {
          organizations: ProspectListOrganization[];
        }
      >,
      string
    >({
      query: (uuid) => ({
        url: endpoints.prospects.get.view_list_organizations(uuid),
        method: 'GET',
      }),
      onQueryStarted: (uuid, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.view_list_organizations(uuid),
        });
      },
    }),

    getOrganizations: builder.query<
      {
        uuid: string;
        name: string;
        static_slug: string;
      }[],
      string
    >({
      query: (query) => ({
        url: endpoints.prospects.get.v2_autocomplete_organizations(query),
        method: 'GET',
      }),
    }),

    getProfiles: builder.query<
      {
        uuid: string;
        name: string;
        static_slug: string;
        organization__name: string;
      }[],
      string
    >({
      query: (query) => ({
        url: endpoints.prospects.get.v2_autocomplete_profiles(query),
        method: 'GET',
      }),
    }),

    /**
     * Get list of profiles for preview in list builder v4.
     */
    getProfilesListPreview: builder.query<
      GetProfilesListPreviewResponse,
      GetProfilesListPreviewArgs
    >({
      query: (body) => ({
        url: endpoints.prospects.post.preview_profiles_list,
        method: 'POST',
        body,
      }),
      transformResponse: extractResults,
    }),

    /**
     * Get list of organizations for preview in list builder v4.
     */
    getOrganizationsListPreview: builder.query<
      GetOrganizationsListPreviewResponse,
      GetOrganizationsListPreviewArgs
    >({
      query: (body) => ({
        url: endpoints.prospects.post.preview_orgs_list,
        method: 'POST',
        body,
      }),
      transformResponse: extractResults,
    }),

    /**
     * Get profiles and organization details to display in autocompletes when editing.
     * This is needed because we store some of them only by UUID; this gives us the associate names to display in Chip components.
     */
    getHydrateList: builder.query<GetHydrateListResponse, string>({
      query: (uuid) => ({
        url: endpoints.prospects.get.hydrateList(uuid),
      }),
      transformResponse: extractResults,
    }),

    /**
     * Mutation function to update ICP (Ideal Customer Profile) parameters for a prospect list.
     * @param {string} uuid - The UUID of the prospect list to update.
     * @param {string} icpParams - The updated ICP parameters as a string.
     * @returns {ApiResponseBase} - An API response indicating the success or failure of the update.
     */
    patchUpdateIcpParams: builder.mutation<
      ApiResponseBase,
      { uuid: string; icpParams: string }
    >({
      query: ({ uuid, icpParams = null }) => ({
        url: endpoints.prospects.patch.update_icp_params(uuid),
        method: 'PATCH',
        body: {
          data: {
            icp_params: icpParams,
          },
        },
      }),
      invalidatesTags: ['ProspectLists'],
      onQueryStarted: ({ uuid }, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.update_icp_params,
          defaultErrorMessage: errorMessages.update_icp_params(uuid),
        });
      },
    }),

    patchUpdateWizardParams: builder.mutation<
      ApiResponseBase,
      {
        uuid: string;
        wizardParams: WizardListParams;
        averageDealSize?: number;
        listName?: string;
      }
    >({
      query: ({ uuid, wizardParams, averageDealSize, listName }) => ({
        url: endpoints.prospects.patch.update_wizard_params(uuid),
        method: 'PATCH',
        body: {
          data: {
            wizard_params: JSON.stringify(wizardParams),
            ...(averageDealSize ? { average_deal_size: averageDealSize } : {}),
            ...(listName ? { name: listName } : {}),
          },
        },
      }),
      invalidatesTags: ['ProspectLists'],
      onQueryStarted: ({ uuid }, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.update_icp_params,
          defaultErrorMessage: errorMessages.update_wizard_params(uuid),
        });
      },
    }),

    /**
     * Mutation function to update whether to follow organization members within a prospect list.
     * @param {string} uuid - The UUID of the prospect list to update.
     * @param {boolean} followOrgMembers - A boolean indicating whether to follow organization members in the list.
     * @returns {ApiResponseBase} - An API response indicating the success or failure of the update.
     */
    patchFollowOrgMembers: builder.mutation<
      ApiResponseBase,
      { uuid: string; followOrgMembers: boolean }
    >({
      query: ({ uuid, followOrgMembers }) => ({
        url: endpoints.prospects.patch.follow_org_members(uuid),
        method: 'PATCH',
        body: {
          follow_org_members: followOrgMembers,
        },
      }),
      onQueryStarted: ({ followOrgMembers }, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage:
            successMessages.follow_org_members(followOrgMembers),
          defaultErrorMessage: errorMessages.follow_org_members,
        });
      },
    }),

    /**
     * Mutation function to update deal values for a prospect list.
     * @param {string} uuid - The UUID of the prospect list to update.
     * @param {object} dealValues - An object containing deal values to update.
     * @returns {ApiResponseBase} - An API response indicating the success or failure of the update.
     */
    patchDealValues: builder.mutation<
      ApiResponseBase,
      {
        uuid: string;
        dealValues: {
          average_deal_size: number;
        };
      }
    >({
      query: ({ uuid, dealValues }) => ({
        url: endpoints.prospects.patch.deal_values(uuid),
        method: 'PATCH',
        body: {
          average_deal_size: dealValues.average_deal_size,
        },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.deal_values,
        });
      },
    }),

    /**
     * Mutation function to transfer ownership of a prospect list.
     * @param {string} listId - The ID of the prospect list to transfer ownership.
     * @param {string} newOwnerEmail - The email of the new owner.
     * @returns {ApiResponseBase} - An API response indicating the success or failure of the transfer.
     */
    patchTransferOwner: builder.mutation<
      ApiResponseBase,
      { listId: string; newOwnerEmail: string }
    >({
      query: ({ listId, newOwnerEmail }) => ({
        url: endpoints.prospects.patch.transfer_ownership(listId),
        method: 'PATCH',
        body: {
          new_owner_email: newOwnerEmail,
        },
      }),
      invalidatesTags: (result, error, { listId }) => [
        'ProspectLists',
        { type: 'ProspectLists', uuid: listId },
      ],
      onQueryStarted: async (
        { listId, newOwnerEmail },
        { dispatch, queryFulfilled }
      ) => {
        // Optimistically update the owner in the getMyProspectLists query cache
        const patchResult = dispatch(
          prospectsApi.util.updateQueryData(
            'getMyProspectLists',
            undefined,
            (draft) => {
              if (draft?.success) {
                const listToUpdate = draft.prospect_lists.find(
                  (list) => list.uuid === listId
                );
                if (listToUpdate) {
                  // Find the team member who will be the new owner
                  const newOwner = listToUpdate.shared_with.find(
                    (member) => member.email === newOwnerEmail
                  );
                  if (newOwner) {
                    // Store the current owner information
                    const previousOwner = { ...listToUpdate.owner };

                    // Update the owner
                    listToUpdate.owner = {
                      name: newOwner.name,
                      email: newOwner.email,
                      role: 'Owner',
                      profile: newOwner.url || '',
                      avatar: newOwner.avatar || '',
                      uuid: newOwner.team_uuid || '',
                    };

                    // Remove the new owner from shared_with
                    listToUpdate.shared_with = listToUpdate.shared_with.filter(
                      (member) => member.email !== newOwnerEmail
                    );

                    // Add the previous owner to shared_with as an Editor
                    listToUpdate.shared_with.push({
                      name: previousOwner.name,
                      email: previousOwner.email,
                      role: 'Editor',
                      url: previousOwner.profile,
                      avatar: previousOwner.avatar,
                    });
                  }
                }
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (error) {
          // If the API call fails, undo the optimistic update
          patchResult.undo();
        }

        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.transfer_ownership(listId),
          defaultErrorMessage: errorMessages.transfer_ownership(listId),
        });
      },
    }),

    /**
     * Mutation function to initiate the export of a prospect list with specified parameters.
     * @param {string} uuid - The UUID of the prospect list to export.
     * @param {ExportType} exportType - The type of export to perform (e.g., CSV, Excel).
     * @param {string} filename - The desired filename for the exported file.
     * @returns {ApiResponseBase<PostProspectListExportResponse>} - An API response containing export status.
     */
    postProspectListExport: builder.mutation<
      ApiResponseBase<PostProspectListExportResponse>,
      { uuid: string; exportType: ExportType; filename: string }
    >({
      query: ({ uuid, exportType }) => ({
        url: endpoints.prospects.post.export(uuid, exportType),
        method: 'POST',
      }),
      onQueryStarted({ filename }, { dispatch, queryFulfilled }) {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.export_list(filename),
          defaultErrorMessage: errorMessages.export_list(filename),
        });
      },
    }),

    // TODO: what is this endpoint? get rid of it?
    postProspectListEnrich: builder.query<ApiResponseBase, { uuid: string }>({
      query: ({ uuid }) => ({
        url: endpoints.prospects.post.enrich(uuid),
        method: 'POST',
      }),
      onQueryStarted({ uuid }, { dispatch, queryFulfilled }) {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.enrich_list(uuid),
          defaultErrorMessage: errorMessages.enrich_list(uuid),
        });
      },
    }),

    /**
     * Mutation function to delete a prospect list.
     * @param {string} uuid - The UUID of the prospect list to delete.
     * @param {string} listName - The name of the prospect list to delete.
     * @returns {ApiResponseBase} - An API response indicating the success or failure of the deletion.
     */
    deleteProspectList: builder.mutation<
      ApiResponseBase,
      { uuid: string; listName: string }
    >({
      query: ({ uuid }) => ({
        url: endpoints.prospects.delete.delete_list(uuid),
        method: 'DELETE',
      }),
      invalidatesTags: ['ProspectLists'],
      onQueryStarted({ listName }, { dispatch, queryFulfilled }) {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.delete_list(listName),
          defaultErrorMessage: errorMessages.delete_list(listName),
        });
      },
    }),

    /**
     * Mutation function to create a new prospect list.
     * @param {string} listName - The name of the new prospect list.
     * @returns {ApiResponse<PutCreateListResponse>} - An API response containing information about the created list.
     */
    putCreateList: builder.mutation<
      ApiResponse<PutCreateListResponse>,
      CreateListArgs
    >({
      query: ({
        listName,
        icpBuilder,
        listType,
        listSource,
        listEntityType,
        icpParams = null,
        wizardParams = null,
      }) => ({
        url: endpoints.prospects.put.create_list,
        method: 'PUT',
        body: {
          name: listName,
          list_type: listType,
          list_source: listSource,
          list_entity_type: listEntityType,
          smart_list: icpBuilder,
          icp_params: icpParams,
          wizard_params: wizardParams,
        },
      }),
      onQueryStarted({ listName }, { dispatch, queryFulfilled }) {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.create_list(listName),
          defaultErrorMessage: errorMessages.create_list(listName),
        });
      },
      invalidatesTags: ['ProspectLists'],
    }),

    /**
     * Mutation function to clone a prospect list with a new name.
     * @param {string} uuid - The UUID of the prospect list to clone.
     * @param {string} listName - The name of the new cloned prospect list.
     * @returns {ApiResponseBase<ProspectList>} - An API response containing information about the cloned list.
     */
    putCloneList: builder.mutation<
      ApiResponseBase<ProspectList>,
      { uuid: string; listName: string }
    >({
      query: ({ uuid }) => ({
        url: endpoints.prospects.put.clone_list(uuid),
        method: 'PUT',
      }),
      onQueryStarted: ({ listName }, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.clone_list(listName),
          defaultErrorMessage: errorMessages.clone_list(listName),
        });
      },
      invalidatesTags: ['ProspectLists'],
    }),

    /**
     * Mutation function to add prospect to a list
     * @returns {ApiResponseBase} - An API response indicating the success or failure of the deletion.
     */
    putAddToList: builder.mutation<
      ApiResponseBase,
      { listId: string; orgOrProfileId: string; listName: string }
    >({
      query: ({ listId, orgOrProfileId }) => ({
        url: endpoints.prospects.put.add_to_list(listId),
        method: 'PUT',
        body: { ob_uuid: orgOrProfileId },
      }),
      onQueryStarted: ({ listName }, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.add_to_list(listName),
          defaultErrorMessage: errorMessages.add_to_list(listName),
        });
      },
      invalidatesTags: ['EventAttendees', 'EventSponsors'],
    }),

    /**
     * Query function to retrieve prospect lists for the team.
     * @returns {ApiResponseBase<GetTeamProspectListsResponse>} - An API response containing prospect lists for the team.
     */
    getTeamProspectLists: builder.query<
      ApiResponseBase<GetTeamProspectListsResponse>,
      void
    >({
      query: () => ({
        url: endpoints.prospects.get.team_lists,
        method: 'GET',
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.team_lists,
        });
      },
      providesTags: ['ProspectLists'],
    }),

    /**
     * Query function to retrieve autocomplete suggestions for organizations based on a search query.
     * @param {string} query - The search query for autocomplete suggestions.
     * @returns {ApiResponseBase<GetAutocompleteOrgs>} - An API response containing autocomplete suggestions for organizations.
     */
    getAutocompleteOrgs: builder.query<
      ApiResponse<GetAutocompleteOrgs, 'results'>,
      string
    >({
      query: (query) => ({
        url: endpoints.autocomplete.get.orgs(query),
        method: 'GET',
      }),
      transformResponse: extractResults,
      onQueryStarted: (query, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          forceSuccess: true,
          defaultErrorMessage: errorMessages.autocomplete_org(query),
        });
      },
    }),
    getAutocompletePeople: builder.query<
      ApiResponse<GetAutocompletePeople, 'results'>,
      string
    >({
      query: (query) => ({
        url: endpoints.autocomplete.get.people(query),
        method: 'GET',
      }),
      transformResponse: extractResults,
      onQueryStarted: (query, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          forceSuccess: true,
          defaultErrorMessage: errorMessages.autocomplete_people(query),
        });
      },
    }),

    // Converting MArcelo's axios code to RTK incase we need it
    getAutocompleteOptions: builder.query<AutocompleteOption[], string>({
      query: (inputValue) => ({
        url: endpoints.autocomplete.get.prospects(inputValue),
        method: 'GET',
      }),
      transformResponse: (response: AutocompleteOption[], meta, arg) => {
        const label = arg; // Assuming `label` is passed as the second argument in the hook
        return response.filter(
          (item) =>
            (label === 'People' && item.type === 'profile') ||
            (label === 'Organization' && item.type === 'org')
        );
      },
      onQueryStarted: (inputValue, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: 'Error fetching autocomplete options',
        });
      },
    }),
    /**
     * Query function to post and retrieve prospect profiles based on a request body.
     * @param {PostProspectsBody} body - The request body containing information to fetch prospect profiles.
     * @returns {ApiResponseBase<{
     *   count: number;
     *   results: {
     *     profiles: ProspectProfileFromAPI[];
     *   };
     * }>} - An API response containing prospect profiles based on the request body.
     */
    postProspectProfiles: builder.query<
      ApiResponseBase<{
        count: number;
        results: ProspectProfileFromAPI[];
      }>,
      PostProspectsBody
    >({
      query: (body: PostProspectsBody) => ({
        url: endpoints.prospects.post.prospect_profiles,
        method: 'POST',
        body,
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.prospect_profiles,
        });
      },
    }),

    /**
     * Query function to post and retrieve prospect organizations based on a request body.
     * @param {PostProspectsBody} body - The request body containing information to fetch prospect organizations.
     * @returns {ApiResponseBase<{
     *   count: number;
     *   results: ProspectOrgFromAPI[];
     * }>} - An API response containing prospect organizations based on the request body.
     */
    postProspectOrgs: builder.query<
      ApiResponseBase<{
        count: number;
        results: ProspectOrgFromAPI[];
      }>,
      PostProspectsBody
    >({
      query: (body: PostProspectsBody) => ({
        url: endpoints.prospects.post.prospect_orgs,
        method: 'POST',
        body,
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: errorMessages.prospect_orgs,
        });
      },
    }),

    /**
     * Mutation function to rename a prospect list.
     * @param {string} uuid - The UUID of the prospect list to update.
     * @param {string} new_name - A string recording the new name.
     * @returns {ApiResponseBase} - An API response indicating the success or failure of the update.
     */
    patchListName: builder.mutation<
      ApiResponseBase,
      { uuid: string; new_name: string }
    >({
      query: ({ uuid, new_name }) => ({
        url: endpoints.prospects.patch.rename_list(uuid),
        method: 'PATCH',
        body: {
          new_name,
        },
      }),
      onQueryStarted: ({ new_name }, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.rename_list(new_name),
          defaultErrorMessage: errorMessages.rename_list(new_name),
        });
      },
      invalidatesTags: ['ProspectLists'],
    }),
    /**
     * Mutation function to toggle crm_list access for a prospect list.
     * @param {string} uuid - The UUID of the prospect list to update.
     * @param {boolean} crm_list - A boolean indicating whether to enable crm_list access.
     * @returns {ApiResponseBase} - An API response indicating the success or failure of the update.
     */
    patchCrmList: builder.mutation<
      ApiResponseBase,
      { uuid: string; crm_list: boolean }
    >({
      query: ({ uuid, crm_list }) => ({
        url: endpoints.prospects.patch.toggle_crm_list_active(uuid),
        method: 'PATCH',
        body: {
          crm_list,
        },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: '',
          defaultErrorMessage: '',
        });
      },
    }),

    /**
     * Mutation function to change roles for a prospect list.
     * @param {string} uuid - The UUID of the prospect list to update.
     * @param {boolean} notify - A boolean indicating whether to notify the new roles.
     * @param {string} message - A string containing the notification message.
     * @param {Role[]} roles - An array of roles to assign to the prospect list.
     * @returns {ApiResponseBase} - An API response indicating the success or failure of the update.
     */
    patchChangeRoles: builder.mutation<
      ApiResponseBase<PatchChangeRolesResponse>,
      {
        uuid: string;
        notify: boolean;
        message: string;
        roles: Role[];
      }
    >({
      query: ({ uuid, notify, message, roles }) => ({
        url: endpoints.prospects.patch.change_roles(uuid),
        method: 'PATCH',
        body: {
          listId: uuid,
          notify,
          notify_message: message,
          new_roles: roles,
        },
      }),
      invalidatesTags: (result, error, { uuid }) => [
        'ProspectLists',
        { type: 'ProspectLists', uuid },
      ],
      onQueryStarted: async ({ uuid, roles }, { dispatch, queryFulfilled }) => {
        // Optimistically update the roles in the getMyProspectLists query cache
        const patchResult = dispatch(
          prospectsApi.util.updateQueryData(
            'getMyProspectLists',
            undefined,
            (draft) => {
              if (draft?.success) {
                const listToUpdate = draft.prospect_lists.find(
                  (list) => list.uuid === uuid
                );
                if (listToUpdate) {
                  // Process each role change
                  for (const { email, role } of roles) {
                    // Find if the user is already in shared_with
                    const existingMemberIndex =
                      listToUpdate.shared_with.findIndex(
                        (member) => member.email === email
                      );

                    if (existingMemberIndex !== -1) {
                      // Update existing member's role
                      listToUpdate.shared_with[existingMemberIndex].role = role;
                    } else {
                      // This is a new member being added
                      // We don't have all the user details here, so we'll add what we can
                      // The full refresh will get the complete data
                      listToUpdate.shared_with.push({
                        email,
                        role,
                        name: email.split('@')[0], // Temporary name until refresh
                        url: '',
                        avatar: '',
                      });
                    }
                  }
                }
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch (error) {
          // If the API call fails, undo the optimistic update
          patchResult.undo();
        }

        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultSuccessMessage: successMessages.change_roles(uuid),
          defaultErrorMessage: errorMessages.change_roles(uuid),
        });
      },
    }),
  }),
});
