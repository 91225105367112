import React, { useCallback, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useListBuilder } from '../ListBuilderProvider';
import {
  PeopleOrganizationsInputMethod,
  PeopleOrganizationsInputMethods,
} from '../types';
import { useWatch } from 'react-hook-form';
import { CSVUploadProvider } from '../CSVUpload/CSVUploadContext';
import { PeopleOrganizationsCSVUploadForm } from '../CSVUpload/PeopleOrganizationsCSVUploadForm';
import { Option } from 'hooks/Prospects/useIcpFilter';
import { listBuilderInitialState } from '../listBuilderReducer';
import { usePosthog } from 'analytics';

export function PeopleOrganizationsInputMethodCard() {
  const { sendPosthogEvent } = usePosthog();

  const {
    state: { peopleInputMethod, peopleOrganizationsInputMethod },
    dispatch,
    form,
    initialFormValues,
  } = useListBuilder();

  const listType = useWatch({
    control: form.control,
    name: 'listType',
  });

  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });

  const listName = useWatch({
    control: form.control,
    name: 'listName',
  });

  useEffect(() => {
    return () => {
      dispatch({
        type: 'PEOPLE_ORGANIZATIONS_INPUT_METHOD_CHANGED',
        peopleOrganizationsInputMethod:
          listBuilderInitialState.peopleOrganizationsInputMethod as PeopleOrganizationsInputMethod,
      });
    };
  }, [dispatch]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInputMethod = event.target
        .value as PeopleOrganizationsInputMethod;
      const inputMethodLabel = PeopleOrganizationsInputMethods[newInputMethod];

      // Determine event name based on input method
      let eventName = `List Builder > ${inputMethodLabel} selected`;

      // Send analytics event
      if (eventName) {
        sendPosthogEvent(eventName, {
          listName,
          listType,
          listContents: listEntityType,
          inputMethod: newInputMethod,
        });
      }

      dispatch({
        type: 'PEOPLE_ORGANIZATIONS_INPUT_METHOD_CHANGED',
        peopleOrganizationsInputMethod: newInputMethod,
      });
    },
    [dispatch, sendPosthogEvent, listName, listType, listEntityType]
  );

  const handleFileUploaded = useCallback(
    (file) => {
      sendPosthogEvent('List Builder > CSV uploaded', {
        listName,
        listType,
        listContents: listEntityType,
        fieldName: 'includedOrganizations',
        fileName: file.name,
      });
    },
    [sendPosthogEvent, listName, listType, listEntityType]
  );

  const onCsvUploadChanged = useCallback(
    (newValues: Option[]) => {
      form.setValue('includedOrganizations', newValues);
    },
    [form]
  );

  const inputMethodsToDisplay = Object.entries(
    PeopleOrganizationsInputMethods
  ).filter((inputMethod) => {
    if (listType === 'competitors') {
      return ['manual', 'csv'].includes(inputMethod[0]);
    }

    if (listType === 'customers') {
      if (peopleInputMethod === 'jobTitles') {
        return ['manual', 'csv'].includes(inputMethod[0]);
      }

      return false;
    }

    if (listType === 'prospects' && peopleInputMethod === 'jobTitles') {
      return ['manual', 'csv', 'describe'].includes(inputMethod[0]);
    }

    if (listType === 'pipeline' && peopleInputMethod === 'jobTitles') {
      return ['manual', 'csv'].includes(inputMethod[0]);
    }

    return false;
  });

  return (
    <Card sx={{ mb: 2 }} data-testid="people-organizations-input-method-card">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {listType === 'customers'
            ? 'Which organizations are your customers?'
            : listType === 'competitors'
            ? 'Which organizations are your competitors?'
            : listType === 'prospects'
            ? 'Which organizations are your prospects?'
            : listType === 'pipeline'
            ? 'Which organizations are in your pipeline?'
            : 'Which organizations do these people work at?'}
        </Typography>

        <Stack spacing={2}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="peopleOrganizationsInputMethod"
            value={peopleOrganizationsInputMethod}
            onChange={onChange}
          >
            {inputMethodsToDisplay.map(([key, value]) => (
              <FormControlLabel value={key} control={<Radio />} label={value} />
            ))}
          </RadioGroup>

          {/* {peopleOrganizationsInputMethod === 'crm' ? <CRMFieldsForm /> : null} */}

          {/* {peopleOrganizationsInputMethod === 'manual' ? (
            <AddPeopleOrganizationForm />
          ) : null} */}

          {peopleOrganizationsInputMethod === 'csv' ? (
            <CSVUploadProvider
              dataType="organizations"
              onValuesChanged={onCsvUploadChanged}
              initialValues={initialFormValues.includedOrganizations}
              onFileUploaded={handleFileUploaded}
            >
              <PeopleOrganizationsCSVUploadForm />
            </CSVUploadProvider>
          ) : null}

          {/*

          {peopleOrganizationsInputMethod === 'jobTitles' ? (
            <PeopleJobTitleForm />
          ) : null} */}
        </Stack>
      </CardContent>
    </Card>
  );
}
