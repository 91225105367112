import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import { useListBuilder } from '../ListBuilderProvider';
import { organizationSizeRanges } from 'components/ProspectList/ICPBuilder/presets';
import { Controller } from 'react-hook-form';

export function OrganizationsSizeCard() {
  const { form, initialFormValues } = useListBuilder();

  const [selections, setSelections] = React.useState<string[]>(
    initialFormValues.includedEmployeeRanges
  );

  // Sync changed value to main form.
  useEffect(() => {
    form.setValue('includedEmployeeRanges', selections);

    return () => {
      form.resetField('includedEmployeeRanges');
    };
  }, [form, selections]);

  const handleChange = (event: SelectChangeEvent<typeof selections>) => {
    const {
      target: { value },
    } = event;
    setSelections(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Card sx={{ marginBottom: 2 }} data-testid="organizations-size-card">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          What size of organizations do you want to include?
        </Typography>
        <Controller
          name="includedEmployeeRanges"
          control={form.control}
          render={({ field }) => (
            <Select
              multiple
              fullWidth
              value={selections}
              onChange={handleChange}
              data-testid="organizations-size-select"
            >
              {organizationSizeRanges.map((range) => (
                <MenuItem key={range} value={range}>
                  {range}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </CardContent>
    </Card>
  );
}
