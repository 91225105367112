import { read, utils } from 'xlsx';

export function readXlsAsCsv(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = read(data, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const csv = utils.sheet_to_csv(worksheet);
      resolve(csv);
    };
    reader.onerror = reject;
    reader.readAsBinaryString(file);
  });
}

export function readCsv(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result;
      if (typeof content === 'string') {
        resolve(content);
      }
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
}

export async function processUploadedFile({
  file,
  onComplete,
}: {
  file: File;
  onComplete: (data: string) => void;
}) {
  const extension = file.name.split('.').pop();

  if (extension === 'csv') {
    const csvData = await readCsv(file);

    onComplete(csvData);
  } else if (extension === 'xls' || extension === 'xlsx') {
    const csvData = await readXlsAsCsv(file);

    onComplete(csvData);
  }
}
