import { Alert } from '@mui/material';
import { useCSVUploadContext } from './CSVUploadContext';

export function CSVUploadErrorAlert() {
  const { errorAlert } = useCSVUploadContext();

  if (!errorAlert) {
    return null;
  }

  return <CSVUploadErrorAlertUI message={errorAlert} />;
}

export function CSVUploadErrorAlertUI({ message }: { message: string }) {
  return (
    <Alert
      severity="error"
      sx={(theme) => ({ backgroundColor: theme.vdxColors.vdxRed[250] })}
    >
      {message}
    </Alert>
  );
}
