import { ListWizardWizardParams } from 'components/Wizard/WizardConfig';
import {
  OrganizationsInputMethod,
  PeopleInputMethod,
  PeopleOrganizationsInputMethod,
} from './types';
import {
  ProspectListOrganization,
  ProspectListProfile,
} from 'modules/prospects/types';

export interface ListBuilderState {
  organizationsInputMethod?: OrganizationsInputMethod;
  peopleInputMethod?: PeopleInputMethod;
  peopleOrganizationsInputMethod?: PeopleOrganizationsInputMethod;
  saveListRequested: boolean;
  addPersonRequested: boolean;
  apiParams: Partial<ListWizardWizardParams>;
  organizations?: ProspectListOrganization[];
  people?: ProspectListProfile[];
  hydratedOrganizationMap?: Record<string, string>;
  hydratedProfileMap?: Record<string, string>;
}

// Define possible actions
export type ListBuilderAction =
  | { type: 'LIST_NAME_CHANGED'; listName: string | undefined }
  | {
      type: 'ORGANIZATIONS_INPUT_METHOD_CHANGED';
      organizationsInputMethod: OrganizationsInputMethod;
    }
  | {
      type: 'PEOPLE_INPUT_METHOD_CHANGED';
      peopleInputMethod?: PeopleInputMethod;
    }
  | {
      type: 'PEOPLE_ORGANIZATIONS_INPUT_METHOD_CHANGED';
      peopleOrganizationsInputMethod?: PeopleOrganizationsInputMethod;
    }
  | { type: 'SAVE_LIST_REQUESTED' }
  | { type: 'CLEAR_SAVE_LIST_REQUEST' }
  | { type: 'SET_ORGANIZATIONS'; organizations: ProspectListOrganization[] }
  | { type: 'SET_PEOPLE'; people: ProspectListProfile[] };

// Create the initial state
export const listBuilderInitialState: ListBuilderState = {
  organizationsInputMethod: undefined,
  peopleInputMethod: undefined,
  peopleOrganizationsInputMethod: undefined,
  saveListRequested: false,
  addPersonRequested: false,
  apiParams: {},
};

export function listBuilderReducer(
  state: ListBuilderState,
  action: ListBuilderAction
): ListBuilderState {
  switch (action.type) {
    case 'ORGANIZATIONS_INPUT_METHOD_CHANGED': {
      return {
        ...state,
        organizationsInputMethod: action.organizationsInputMethod,
      };
    }

    case 'PEOPLE_INPUT_METHOD_CHANGED': {
      return {
        ...state,
        peopleInputMethod: action.peopleInputMethod,
      };
    }

    case 'PEOPLE_ORGANIZATIONS_INPUT_METHOD_CHANGED': {
      return {
        ...state,
        peopleOrganizationsInputMethod: action.peopleOrganizationsInputMethod,
      };
    }

    case 'SAVE_LIST_REQUESTED': {
      return {
        ...state,
        saveListRequested: true,
      };
    }

    case 'CLEAR_SAVE_LIST_REQUEST': {
      return {
        ...state,
        saveListRequested: false,
      };
    }

    case 'SET_ORGANIZATIONS': {
      return {
        ...state,
        organizations: action.organizations,
      };
    }

    case 'SET_PEOPLE': {
      return {
        ...state,
        people: action.people,
      };
    }

    default:
      return state;
  }
}
