// TODO: Refactor this component and consolidate with TagsGroup once we change the UI
import React, { useRef, useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { Chip, Link, Stack } from '@mui/material';
import { AvatarTooltip } from 'components/Prospects/AvatarTooltip';
import { Density } from 'modules/types';
import { ListOwnershipDetails } from 'hooks/EDP/useProspects';
import { ChipsListSubMenu } from './ChipsListSubMenu';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { ListEntityTypeIcon } from 'components/common/Lists/ListEntityType';

interface ChipsListProps {
  objects: ListOwnershipDetails[];
  density?: Density;
}

// #region styles

const BoxWrapperStyle = css`
  overflow: auto;
  margin: 0;
  padding: 0;
  .MuiChip-root:first-child {
    margin-left: 0;
  }
`;

// #endregion

export const ChipsList = ({ objects = [], density }: ChipsListProps) => {
  const size = density === 'compact' ? 'small' : 'medium';

  const parentRef = useRef<HTMLDivElement>(null);

  const [displayedCount, setDisplayedCount] = useState<number>(objects.length);
  const [isOverflowing, setIsOverflowing] = useState(true);

  useEffect(() => {
    let displayCount = 2;
    setDisplayedCount(displayCount);
    setIsOverflowing(displayCount < objects.length);
  }, [objects.length]);

  // map hidden objects to a format that can be used by OverflowCardGroup
  const overflowObjects = objects.slice(displayedCount);

  const visibleObjects =
    overflowObjects.length >= 1 ? objects.slice(0, displayedCount) : objects;

  const moreCount = overflowObjects.length;

  // check for owned lists only(filter out shared lists)
  const { data, isLoading } =
    prospectsApi.useGetMyProspectListsShortQuery(true);
  const [myProspectListsMap, setMyProspectListsMap] = useState(new Map());

  useEffect(() => {
    if (!isLoading && data?.success) {
      const { prospect_lists } = data;
      const newProspectListsMap = new Map();
      prospect_lists.forEach((prospectList) => {
        newProspectListsMap.set(prospectList.uuid, prospectList);
      });
      setMyProspectListsMap(newProspectListsMap);
    }
  }, [isLoading, data]);
  return (
    <Stack
      ref={parentRef}
      direction="row"
      alignItems="center"
      className={BoxWrapperStyle}
    >
      {visibleObjects.map((object, index) => (
        <Chip
          icon={
            <ListEntityTypeIcon
              value={object.list_entity_type}
              slotProps={{
                icon: {
                  sx: {
                    marginLeft: '4px',
                  },
                },
              }}
            />
          }
          label={
            <AvatarTooltip
              key={`tooltip-${index}`}
              title={object.list_name}
              subheader={object.owner_name}
              filterable={Boolean(myProspectListsMap.get(object.list_uuid))}
              url={''}
            >
              <span>{object.list_name}</span>
            </AvatarTooltip>
          }
          size={size}
        />
      ))}
      {/* TODO: Objects here differ from the objects in OverflowCardGroup. Find a way to consolidate this with OverflowCardGroup component */}
      {isOverflowing && (
        <ChipsListSubMenu
          objects={overflowObjects}
          myProspectListsMap={myProspectListsMap}
          keyPrefix="chips-group"
        >
          <Link
            component="button"
            underline="none"
            sx={{
              color: (theme) => theme.palette.grey.borderGrey,
              borderRadius: '8px',
              padding: '4px',
              marginLeft: '4px',
              border: (theme) => `1px solid ${theme.palette.grey.borderGrey}`,
              textWrap: 'nowrap',
            }}
          >
            + {moreCount}
          </Link>
        </ChipsListSubMenu>
      )}
    </Stack>
  );
};
