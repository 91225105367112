import React, { createContext, useCallback, useContext, useState } from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  useTheme,
} from '@mui/material';
import {
  DateRange,
  PickersActionBarProps,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  PickersShortcutsItem,
  PickersShortcutsItemContext,
  PickersShortcutsProps,
  StaticDateRangePicker,
  usePickerLayout,
} from '@mui/x-date-pickers-pro';
import { getFormattedDateInterval } from 'utils/date';
import dayjs, { Dayjs } from 'dayjs';
import { MuiIconManifest } from 'utils/iconManifest';

// #region Types

interface PlannerDateRangePickerProps {
  handleApply: (
    dateRange: DateRange<Dayjs>,
    shortcut?: PickersShortcutsItemContext
  ) => void;
  handleDelete?: () => void;
  handleCancel?: () => void;
  existingDate?: {
    startDate?: string | Dayjs | null;
    endDate?: string | Dayjs | null;
    shortcut?: PickersShortcutsItem<DateRange<Dayjs>>;
  };
  initialOpen?: boolean;
  loading?: boolean;
  disabled?: boolean;
  configureShortcuts?: (
    shortcuts: PickersShortcutsItem<DateRange<Dayjs>>[]
  ) => PickersShortcutsItem<DateRange<Dayjs>>[];
  slotProps?: {
    button?: Partial<React.ComponentProps<typeof Button>> & {
      disableChevron?: boolean;
      disablePrependedDateLabel?: boolean;
      colorWhenSelected?: React.ComponentProps<typeof Button>['color']; // default is 'primary'
      variantWhenSelected?: React.ComponentProps<typeof Button>['variant']; // default is 'rounded'
      variantWhenNotSelected?: React.ComponentProps<typeof Button>['variant']; // default is 'roundedOutlined'
    };
    popover?: Partial<React.ComponentProps<typeof Popover>>;
  };
}

interface DateRangePickerContextType {
  lastSelectedShortcut: PickersShortcutsItemContext | undefined;
  disableApply: boolean;
  handleReset: () => void;
  handleApply: () => void;
}

// #endregion

// #region Constants

const today = dayjs();
// need to use a relatively close years so that the date picker doesn't look funny when the selected year is 1824 or 200000+
const minDate = dayjs('2000-01-01');
const maxDate = today.add(100, 'year');
export const listOfShortcuts: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'All upcoming',
    getValue: () => {
      return [today, maxDate];
    },
  },
  {
    label: 'All previous',
    getValue: () => {
      return [minDate, today];
    },
  },
  {
    label: 'This week',
    getValue: () => {
      return [today.startOf('week'), today.endOf('week').startOf('day')];
    },
  },
  {
    label: 'This month',
    getValue: () => {
      return [today.startOf('month'), today.endOf('month').startOf('day')];
    },
  },
  {
    label: 'This quarter',
    getValue: () => {
      return [today.startOf('quarter'), today.endOf('quarter').startOf('day')];
    },
  },
  {
    label: 'This year',
    getValue: () => {
      return [today.startOf('year'), today.endOf('year').startOf('day')];
    },
  },
  {
    label: 'Year to date (YTD)',
    getValue: () => {
      return [today.startOf('year'), today.endOf('day').startOf('day')];
    },
  },
  {
    label: 'Last week',
    getValue: () => {
      const startOfLastWeek = today.subtract(1, 'week').startOf('week');
      return [startOfLastWeek, startOfLastWeek.endOf('week').startOf('day')];
    },
  },
  {
    label: 'Last month',
    getValue: () => {
      const startOfLastMonth = today.subtract(1, 'month').startOf('month');
      return [startOfLastMonth, startOfLastMonth.endOf('month').startOf('day')];
    },
  },
  {
    label: 'Last quarter',
    getValue: () => {
      const startOfLastQuarter = today
        .subtract(1, 'quarter')
        .startOf('quarter');
      return [
        startOfLastQuarter,
        startOfLastQuarter.endOf('quarter').startOf('day'),
      ];
    },
  },
  {
    label: 'Last year',
    getValue: () => {
      const startOfLastYear = today.subtract(1, 'year').startOf('year');
      return [startOfLastYear, startOfLastYear.endOf('year').startOf('day')];
    },
  },
  {
    label: 'Next week',
    getValue: () => {
      const startOfNextWeek = today.endOf('week').add(1, 'day');
      return [startOfNextWeek, startOfNextWeek.endOf('week').startOf('day')];
    },
  },
  {
    label: 'Next month',
    getValue: () => {
      const startOfNextMonth = today.endOf('month').add(1, 'day');
      return [startOfNextMonth, startOfNextMonth.endOf('month').startOf('day')];
    },
  },
  {
    label: 'Next quarter',
    getValue: () => {
      const startOfNextQuarter = today.endOf('quarter').add(1, 'day');
      return [
        startOfNextQuarter,
        startOfNextQuarter.endOf('quarter').startOf('day'),
      ];
    },
  },
  {
    label: 'Next year',
    getValue: () => {
      const startOfNextYear = today.endOf('year').add(1, 'day');
      return [startOfNextYear, startOfNextYear.endOf('year').startOf('day')];
    },
  },
];

// need to create a context to pass variables to the custom components
const DateRangePickerContext = createContext<
  DateRangePickerContextType | undefined
>(undefined);

// #endregion

// #region Custom components to override default MUI components

export function CustomLayout(
  props: PickersLayoutProps<DateRange<Dayjs | null>, Dayjs, 'day'>
) {
  const { toolbar, tabs, content, actionBar, shortcuts } =
    usePickerLayout(props);

  return (
    <PickersLayoutRoot
      ownerState={props}
      sx={{
        overflow: 'auto',
      }}
    >
      {toolbar}
      {shortcuts}
      <PickersLayoutContentWrapper>
        {tabs}
        {content}
        {actionBar}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
}

// custom shortcuts so we can use ListItem instead of MUI default Chips
const CustomShortCuts = ({
  items,
  changeImportance = 'set',
  onChange,
  isLandscape, // remove unnecessary props so it doesn't get passed to the List
  isValid,
  ...other
}: PickersShortcutsProps<DateRange<Dayjs>>) => {
  const context = useContext(DateRangePickerContext);
  const theme = useTheme();
  if (!context) return null;
  if (items == null || items.length === 0) {
    return null;
  }
  const { lastSelectedShortcut } = context;

  return (
    <List {...other}>
      {items.map((item) => (
        <ListItem
          key={item.label}
          disablePadding
          sx={{
            bgcolor:
              lastSelectedShortcut?.label === item.label
                ? theme.palette.primary.main
                : 'inherit',
            color:
              lastSelectedShortcut?.label === item.label
                ? theme.palette.primary.contrastText
                : 'inherit',
          }}
        >
          <ListItemButton
            onClick={() => {
              onChange(item.getValue({ isValid }), changeImportance, item);
            }}
          >
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

// custom action bar so we can disable the apply button when nothing has changed
function CustomActionBar({ onCancel, actions }: PickersActionBarProps) {
  const context = useContext(DateRangePickerContext);
  if (!context) return null;
  if (actions == null || actions.length === 0) return null;
  const { disableApply, handleReset, handleApply } = context;
  return (
    <DialogActions>
      <Button onClick={onCancel} variant="text">
        Cancel
      </Button>
      <Button onClick={handleReset} variant="text" disabled={disableApply}>
        Reset
      </Button>
      <Button onClick={handleApply} variant="text" disabled={disableApply}>
        Apply
      </Button>
    </DialogActions>
  );
}

// #endregion

export const ButtonDateRangePicker = ({
  handleApply,
  handleDelete,
  handleCancel,
  existingDate,
  initialOpen = false,
  loading = false,
  disabled = false,
  configureShortcuts,
  slotProps,
}: PlannerDateRangePickerProps) => {
  const theme = useTheme();

  // any existing shortcuts
  const [existingShortcutStart, existingShortcutEnd] =
    existingDate?.shortcut?.getValue({ isValid: () => true }) ?? [null, null];
  const allShortcuts = configureShortcuts?.(listOfShortcuts) ?? listOfShortcuts;

  // the last selected shortcut (and hasn't been applied yet)
  const [lastSelectedShortcut, setLastSelectedShortcut] = useState<
    PickersShortcutsItemContext | undefined
  >(
    existingDate?.shortcut?.label
      ? { label: existingDate.shortcut.label }
      : undefined
  );

  // determine existing start and end dates
  const existingStart = existingDate?.shortcut
    ? existingShortcutStart
    : existingDate?.startDate
    ? dayjs(existingDate?.startDate)
    : null;
  const existingEnd = existingDate?.shortcut
    ? existingShortcutEnd
    : existingDate?.endDate
    ? dayjs(existingDate?.endDate)
    : null;

  // state to keep track of the selected date range (and hasn't been applied yet)
  const [[selectedStart, selectedEnd], setDateRange] = useState<
    DateRange<Dayjs>
  >([existingStart, existingEnd]);

  // when the component mounts, set the button as the anchor element
  const [open, setOpen] = useState(initialOpen);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  // #region handlers

  // clear the selected options
  const reset = useCallback(() => {
    setDateRange([existingStart, existingEnd]);
    setLastSelectedShortcut(undefined);
  }, [existingStart, existingEnd, setDateRange, setLastSelectedShortcut]);

  // apply the selected options as filters
  const apply = useCallback(() => {
    setOpen(false);
    handleApply([selectedStart, selectedEnd], lastSelectedShortcut);
  }, [selectedStart, selectedEnd, lastSelectedShortcut, handleApply]);

  // click to open the filter menu
  const openMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  }, []);

  // close the menu without applying
  const cancelMenu = useCallback(() => {
    setOpen(false);
    reset();
    handleCancel?.();
  }, [reset, handleCancel]);

  // #endregion

  // optional props for the button
  const {
    disableChevron,
    disablePrependedDateLabel,
    colorWhenSelected,
    variantWhenSelected,
    variantWhenNotSelected,
    ...restOfButtonProps
  } = slotProps?.button ?? {};

  // the label for the filter button
  let label: string | undefined = 'Date';
  if (existingDate?.shortcut?.label) {
    label = disablePrependedDateLabel
      ? existingDate.shortcut.label
      : `Date: ${existingDate.shortcut.label}`;
  } else if (existingStart && existingEnd) {
    const formattedInterval = getFormattedDateInterval(
      existingStart,
      existingEnd
    );
    label = disablePrependedDateLabel
      ? formattedInterval
      : `Date: ${formattedInterval}`;
  }
  const buttonLabel = label;

  // disable the apply button if nothing has changed
  const startChanged = selectedStart?.isSame(existingStart) ? false : true;
  const endChanged = selectedEnd?.isSame(existingEnd) ? false : true;
  const shortcutChanged =
    lastSelectedShortcut?.label === existingDate?.shortcut?.label
      ? false
      : true;
  const validDateSelection = selectedStart !== null && selectedEnd !== null;

  const disableApply =
    existingDate?.shortcut && lastSelectedShortcut
      ? !shortcutChanged
      : validDateSelection
      ? !startChanged && !endChanged
      : true;
  const hasSelectedDate = selectedStart || selectedEnd;

  return (
    <DateRangePickerContext.Provider
      value={{
        lastSelectedShortcut,
        disableApply,
        handleReset: reset,
        handleApply: apply,
      }}
    >
      <Button
        ref={buttonRef}
        color={hasSelectedDate ? colorWhenSelected ?? 'primary' : 'grey'}
        variant={
          hasSelectedDate
            ? variantWhenSelected ?? 'rounded'
            : variantWhenNotSelected ?? 'roundedOutlined'
        }
        disabled={loading || disabled}
        endIcon={
          loading ? (
            <CircularProgress color="inherit" size={16} />
          ) : hasSelectedDate && handleDelete ? (
            <MuiIconManifest.CloseIcon
              fontSize="small"
              onClick={(event) => {
                setOpen(false);
                handleDelete();
                // prevent click of button
                event.stopPropagation();
              }}
            />
          ) : (
            !disableChevron && <MuiIconManifest.ExpandMoreIcon />
          )
        }
        onClick={openMenu}
        {...restOfButtonProps}
      >
        {loading ? 'Date' : buttonLabel}
      </Button>
      <Popover
        open={open || initialOpen}
        anchorEl={buttonRef.current}
        onClose={cancelMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...slotProps?.popover}
      >
        <StaticDateRangePicker
          onChange={(newValue, ctx) => {
            setDateRange(newValue);
            setLastSelectedShortcut(ctx.shortcut);
          }}
          onClose={cancelMenu}
          value={[selectedStart, selectedEnd]}
          minDate={minDate}
          maxDate={maxDate}
          slots={{
            layout: CustomLayout,
            shortcuts: CustomShortCuts,
            actionBar: CustomActionBar,
          }}
          slotProps={{
            actionBar: {
              sx: {
                borderTop: `1px solid ${theme.palette.divider}`,
              },
              actions: ['cancel', 'clear', 'accept'],
            },
            toolbar: {
              toolbarFormat: 'll',
              sx: {
                gridColumn: '1/4 !important', // for some reason toolbar is right aligned by default
                paddingLeft: theme.spacing(1),
                borderBottom: `1px solid ${theme.palette.divider}`,
              },
            },
            shortcuts: {
              items: allShortcuts,
              dense: true,
              disablePadding: true,
              changeImportance: 'set', // just set, not apply
              sx: {
                overflow: 'auto',
                maxHeight: 359,
                p: 0,
                borderBottom: `1px solid ${theme.palette.divider}`,
              },
            },
          }}
        />
      </Popover>
    </DateRangePickerContext.Provider>
  );
};
