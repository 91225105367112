import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form-mui';
import { useListBuilder } from '../ListBuilderProvider';
import { listTypeOptions } from '../types';
import { FormErrorContainer } from '../FormError';

export function ListDetailsCard() {
  const { form } = useListBuilder();

  return (
    <Card sx={{ mb: 2 }} data-testid="list-details-card">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          List Details
        </Typography>

        <Controller
          name="listName"
          control={form.control}
          render={({ field }) => (
            <TextField label="List Name" fullWidth margin="normal" {...field} />
          )}
        />

        <FormErrorContainer message={form.formState.errors.listName?.message} />

        <Controller
          name="listType"
          control={form.control}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel id="list-type-select">List Type</InputLabel>
              <Select
                label="List Type"
                fullWidth
                {...field}
                data-testid="list-type-select"
              >
                <MenuItem key="none" value="">
                  &nbsp;
                </MenuItem>
                {listTypeOptions.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <FormErrorContainer message={form.formState.errors.listType?.message} />
      </CardContent>
    </Card>
  );
}
