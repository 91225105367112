import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
  Controller,
  FieldPath,
  FieldValues,
  UseFormReturn,
} from 'react-hook-form';

export interface CSVColumnsRowProps<
  TFieldValues extends FieldValues = FieldValues
> {
  columns: string[];
  form: UseFormReturn<TFieldValues>;
  fieldName: FieldPath<TFieldValues>;
  label: string;
}
export function CSVColumnsRow<TFieldValues extends FieldValues = FieldValues>({
  columns,
  form,
  fieldName,
  label,
}: CSVColumnsRowProps<TFieldValues>) {
  return (
    <Controller<TFieldValues>
      name={fieldName}
      control={form.control}
      render={({ field }) => (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id={`${fieldName}-label`}>
            Select {label.toLowerCase()} column
          </InputLabel>
          <Select
            {...field}
            labelId={`${fieldName}-label`}
            id={`${fieldName}`}
            fullWidth
            data-testid={`${fieldName}-label`}
            label={`Select ${label.toLowerCase()} column`}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            {columns.map((column) => (
              <MenuItem key={column} value={column}>
                {column}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
