import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { usePosthog } from 'analytics';
import { UseFormReturn } from 'react-hook-form';
import { ListBuilderFormSchema } from 'components/ListBuilder4/formSchema';

/**
 * Hook to track analytics events when form values change in the ListBuilder4.
 * Automatically sends PostHog events when values change.
 *
 * @param form The form instance from react-hook-form
 */
export function useListBuilderAnalytics(
  form: UseFormReturn<ListBuilderFormSchema>
): void {
  const { sendPosthogEvent } = usePosthog();

  // Watch all relevant form fields
  const listName = useWatch({ control: form.control, name: 'listName' });
  const listType = useWatch({ control: form.control, name: 'listType' });
  const listEntityType = useWatch({
    control: form.control,
    name: 'listEntityType',
  });
  const includedNaics2022Codes = useWatch({
    control: form.control,
    name: 'includedNaics2022Codes',
  });
  const includedEmployeeRanges = useWatch({
    control: form.control,
    name: 'includedEmployeeRanges',
  });

  // Track list type selection
  useEffect(() => {
    if (listType) {
      sendPosthogEvent('List Builder > List Type Selected', {
        listType,
      });
    }
  }, [listType, sendPosthogEvent]);

  // Track organization sizes
  useEffect(() => {
    if (includedEmployeeRanges && includedEmployeeRanges.length > 0) {
      sendPosthogEvent('List Builder > Organization size entered', {
        listName,
        listType,
        listContents: listEntityType,
        sizes: includedEmployeeRanges,
      });
    }
  }, [
    includedEmployeeRanges,
    listName,
    listType,
    listEntityType,
    sendPosthogEvent,
  ]);

  // Track organization industries (NAICS codes)
  useEffect(() => {
    if (includedNaics2022Codes && includedNaics2022Codes.length > 0) {
      sendPosthogEvent('List Builder > Organization industry entered', {
        listName,
        listType,
        listContents: listEntityType,
        industries: includedNaics2022Codes,
      });
    }
  }, [
    includedNaics2022Codes,
    listName,
    listType,
    listEntityType,
    sendPosthogEvent,
  ]);
}
