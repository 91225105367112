import { Box, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
export function CRMListMessageCard() {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="200px" // Adjust this value based on your needs
    >
      <Alert
        severity="info"
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: theme.vdxColors.vdxBlue[5200],
        }}
      >
        CRM lists may take up to one hour to complete. We will notify you when
        the list is ready.
      </Alert>
    </Box>
  );
}
