import { useWatch } from 'react-hook-form';
import { useListBuilder } from '../ListBuilderProvider';
import { ListBuilderState } from '../listBuilderReducer';
import { ListBuilderFormSchema } from '../formSchema';
import { useCallback } from 'react';

export const allModules = [
  'CRMListMessageCard',
  'ExcludeJobTitlesCard',
  'OrganizationsCRMInputForm',
  'OrganizationsCSVUploadForm',
  'OrganizationsIndustryCard',
  'OrganizationsInputMethodCard',
  'OrganizationsSizeCard',
  'PeopleInputMethodCard',
  'PeopleOrganizationsInputMethodCard',
  'PreviewCard',
] as const;

export type AllModules = (typeof allModules)[number];

// Define a rule as a predicate function that determines if a module should be displayed
type Rule = (
  state: Partial<ListBuilderState> & Partial<ListBuilderFormSchema>
) => boolean;

// Define rules for each module
const moduleRules: Record<AllModules, Rule[]> = {
  PeopleInputMethodCard: [
    (state) =>
      !!state.listType &&
      !!state.listEntityType &&
      state.listEntityType === 'profiles',
  ],
  ExcludeJobTitlesCard: [
    (state) =>
      state.listEntityType === 'profiles' &&
      state.peopleInputMethod === 'jobTitles',
  ],
  PeopleOrganizationsInputMethodCard: [
    (state) =>
      state.listEntityType === 'profiles' &&
      state.peopleInputMethod === 'jobTitles',
  ],
  OrganizationsInputMethodCard: [
    (state) =>
      !!state.listEntityType && state.listEntityType === 'organizations',
  ],
  OrganizationsCRMInputForm: [
    (state) =>
      !!state.listType &&
      state.listType === 'pipeline' &&
      !!state.listEntityType &&
      state.listEntityType === 'organizations' &&
      state.organizationsInputMethod === 'crm',
  ],
  OrganizationsCSVUploadForm: [
    (state) =>
      !!state.listType &&
      !!state.listEntityType &&
      state.listEntityType === 'organizations' &&
      state.organizationsInputMethod === 'csv',
  ],
  OrganizationsSizeCard: [
    (state) =>
      state.listType === 'prospects' &&
      ((state.listEntityType === 'profiles' &&
        state.peopleInputMethod === 'jobTitles' &&
        state.peopleOrganizationsInputMethod === 'describe') ||
        (state.listEntityType === 'organizations' &&
          state.organizationsInputMethod === 'describe')),
  ],
  OrganizationsIndustryCard: [
    (state) =>
      state.listType === 'prospects' &&
      ((state.listEntityType === 'profiles' &&
        state.peopleInputMethod === 'jobTitles' &&
        state.peopleOrganizationsInputMethod === 'describe') ||
        (state.listEntityType === 'organizations' &&
          state.organizationsInputMethod === 'describe')),
  ],
  PreviewCard: [
    (state) =>
      ((state.listEntityType === 'profiles' && !!state.people) ||
        (state.listEntityType === 'organizations' && !!state.organizations)) &&
      !(
        state.organizationsInputMethod === 'crm' ||
        state.peopleInputMethod === 'crm'
      ),
  ],
  CRMListMessageCard: [
    (state) =>
      (state.listType === 'pipeline' &&
        state.listEntityType === 'organizations' &&
        state.organizationsInputMethod === 'crm') ||
      (state.listEntityType === 'profiles' &&
        state.peopleInputMethod === 'crm'),
  ],
};

/**
 * Returns the names of modules that should be displayed based on the current state of the form.
 */
export function useModulesToDisplay() {
  const { state, form } = useListBuilder();
  const { listName, listType, listEntityType } = useWatch({
    control: form.control,
  });

  if (!listType || !listEntityType) {
    return [];
  }

  // Evaluate each module against its rules
  return allModules.filter((module) => {
    return moduleRules[module].some((rule) =>
      rule({
        listType,
        listEntityType,
        listName,
        ...state,
      })
    );
  });
}

/**
 * Helper hook to determine if a module should be displayed..
 */
export function useShouldDisplayModule() {
  const modulesToDisplay = useModulesToDisplay();

  return useCallback(
    (name: AllModules) => {
      return modulesToDisplay.includes(name);
    },
    [modulesToDisplay]
  );
}
